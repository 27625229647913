import React from "react";
import "../Style/Banner.css";
import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();
  return (
    <>
      <div className="container d-flex align-items-center banner_image">
        <div className="row">
          <div className="col-md-7 col-lg-6 col-xxl-5">
            <div className="text-start heading mb-3">
              <b>
                The Safety And Training <span className="special">Academy</span>
              </b>
            </div>
            <div className="about text-start mb-3">
              <p>
                The Safety and Training Learning Academy provide courses like
                workplace safety training, compliance essentials, safety
                leadership, behavioural safety and psychology, IOSH Managing
                Safely, Risk Assessment, First Aid and CPR, Fire Risk
                Assessment, Fire Safety, Confined Space Entry, Authorised Gas
                Tester and much more
              </p>
            </div>
            <div className="butt mb-5">
              <button className="butt1" onClick={() => navigate("/allcourses")}>
                {" "}
                View All Courses
              </button>
            </div>
            <div className="tick text-start">
              <span>
                <AiFillCheckCircle />
                &nbsp;various online courses{" "}
              </span>
              <span>
                <AiFillCheckCircle /> &nbsp;expert instruction{" "}
              </span>
              <span>
                <AiFillCheckCircle />
                &nbsp; lifetime access{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
