import React from "react";
import "../Style/Faq.css";
import Navbar from "../Components/Navbar";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { fetchFaqApi } from "../APIS/MainApis";
import { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import { FaQuora } from "react-icons/fa6";

function Faq() {
  const [faqdata, setFaqData] = useState([]);
  useEffect(() => {
    async function fetchFaqData() {
      try {
        const response = await fetchFaqApi();
        if (response && response.data.data && response.data.data.faq_data) {
          setFaqData(response.data.data.faq_data);
        }
      } catch (error) {
        console.log("Error in fetching Faq data", error);
      }
    }
    fetchFaqData();
  }, []);
  return (
    <>
      <Navbar />
      <div className="container faq_container">
        <div className="row">
          <div className="col-12 col-lg-12 mb-3">
            <img src="FAQ.webp" className="faq_image" alt="faqImage"></img>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 mt-5 mb-3">
            <div className="faq_heading text-start">
              Frequently Asked Questions (FAQ)
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="row">
            <MDBAccordion>
              {faqdata?.map((faq, i) => (
                <MDBAccordionItem
                  key={i}
                  collapseId={i + 1}
                  headerTitle={
                    <>
                      <FaQuora />
                      &nbsp; {faq?.faq_title}
                    </>
                  }
                >
                  <div className="text-start">{faq?.faq_desc}</div>
                </MDBAccordionItem>
              ))}
            </MDBAccordion>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Faq;
