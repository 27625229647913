import React from 'react';
import '../Style/MyAccountNavbar.css';

function MyAccountNavbar({ setActiveTab, activeTab }) {

  const handleParaClick = (clickedTab) => {
    setActiveTab(clickedTab);
  };

  return (
    <div>
      <div className="container mb-5 p-0"> 
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg profile_navbarheader p-0">
              <button
                className="navbar-toggler myaccountnavbar_toggle"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav"> 
                <ul className="navbar-nav">
                  <li className={`nav-item profile_navitem ${activeTab === 'myprofile' ? 'active' : ''}`}>
                    <span className="nav-link profile_navlink" onClick={() => handleParaClick('myprofile')}>Profile</span>
                  </li>
                  <li className={`nav-item profile_navitem ${activeTab === 'mycourse' ? 'active' : ''}`}>
                    <span className="nav-link profile_navlink" onClick={() => handleParaClick('mycourse')}>My Courses</span>
                  </li>
                  <li className={`nav-item profile_navitem ${activeTab === 'purchasehistory' ? 'active' : ''}`}>
                    <span className="nav-link profile_navlink" onClick={() => handleParaClick('purchasehistory')}>Purchase History</span>
                  </li>
                  <li className={`nav-item profile_navitem ${activeTab === 'wishlist' ? 'active' : ''}`}>
                    <span className="nav-link profile_navlink" onClick={() => handleParaClick('wishlist')}>WishList</span>
                  </li>
                  <li className={`nav-item profile_navitem ${activeTab === 'mycertificates' ? 'active' : ''}`}>
                    <span className="nav-link profile_navlink" onClick={() => handleParaClick('mycertificates')}>My Certificates</span>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAccountNavbar;
