import React from "react";
import "../Style/CategoryTabHome.css";
import { Navbar, Nav } from "react-bootstrap";

function CategoryTabHome({ setActiveTab, activeTab, categories }) {
  const handleParaClick = (activeTab) => {
    setActiveTab(activeTab);
  };

  return (
    <Navbar expand="lg" className="categoriesTabHome_header p-0">
      <Navbar.Toggle
        aria-controls="category-navbar"
        className="categoryTabHome_toggle"
      />
      <Navbar.Collapse id="category-navbar">
        <Nav className="mr-auto">
          {categories?.map((category, i) => (
            <Nav.Item key={i} className="categorytabhome_navitem">
              <Nav.Link
                className={`categoryTabHome-para ${
                  activeTab === category.name ? "active" : ""
                }`}
                onClick={() => handleParaClick(category.name)}
              >
                {category?.name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CategoryTabHome;
