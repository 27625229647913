import React from "react";
import "../Style/CategoriesTab.css";

function CategoriesTab({ setActiveTab, activeTab }) {


  const handleParaClick = (activeTab) => {
    setActiveTab(activeTab);
  };
 
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="d-flex categoriesTab_header mb-3 mt-3">
              <p
                className={`categoryTab-para ${
                  activeTab === "content" ? "active" : ""
                }`}
                onClick={() => handleParaClick("content")}
              >
                Contents
              </p>
              <p
                className={`categoryTab-para ${
                  activeTab === "description" ? "active" : ""
                }`}
                onClick={() => handleParaClick("description")}
              >
                Description 
              </p>
              <p
                className={`categoryTab-para ${
                  activeTab === "reviews" ? "active" : ""
                }`}
                onClick={() => handleParaClick("reviews")}
              >
                Reviews
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoriesTab;
