import React from "react";
import "../Style/MyCoursesSection.css";
import { useNavigate } from "react-router-dom";
import { PurchasedCoursesApi } from "../APIS/MainApis";
import { useState, useEffect } from "react";

function MyCoursesSection() {
  const token = localStorage.getItem("token");

  const [purchasedCourses, setPurchasedCourses] = useState([]);
  console.log(purchasedCourses, "h");

  useEffect(() => {
    async function fetchpurchasedcourses() {
      PurchasedCoursesApi(token, (data) => {
        const response = data;

        if (response && response?.data && response?.data?.purchasedCourses) {
          const purchasedCoursesArray = Object.values(
            response?.data?.purchasedCourses
          ).flatMap((userCourses) => userCourses?.purchasedCourses);

          setPurchasedCourses(purchasedCoursesArray);
        }
      });
    }
    fetchpurchasedcourses();
  }, [token]);

  const navigate = useNavigate();

  const handleNavigate1 = (courseId) => {
    navigate(`/singlecategory/${courseId}`);
    window.scrollTo(0, 0);
  };

  const handleNavigate2 = (courseId) => {
    navigate(`/contentmenu`, { state: { courseId } });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 mb-5">
        {purchasedCourses?.length === 0 ? (
          <>
            <div style={{ width: "100%" }}>
              You have not purchased any course yet
            </div>
          </>
        ) : (
          <>
            {purchasedCourses?.map((course, i) => (
              <div className="col gy-3" key={i}>
                <div className="card mycoursesection_card">
                  <img
                    src={course?.image}
                    className="mycoursesection_image"
                    alt=""
                  />
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="mycoursesection_level">
                        {course?.level}
                      </span>
                    </div>
                    <p className="truncated-title text-start py-2">
                      {course.course_name}
                    </p>
                    <div className="d-flex mb-2 mycoursesection_whom">
                      By: {course?.author}
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between align-items-center">
                    <div>
                      <button
                        className="mycoursesection_enroll"
                        onClick={() => handleNavigate1(course?.course_id)}
                      >
                        View Details
                      </button>
                    </div>
                    <div>
                      <button
                        className="mycoursesection_enroll"
                        onClick={() => handleNavigate2(course?.course_id)}
                      >
                        Start Lesson
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default MyCoursesSection;
