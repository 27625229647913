import React from "react";
import "../Style/CourseLearning2Description.css";

function CategoryLearning2Description({ description }) {
  const theObj = { __html: description };
  return (
    <>
      <div className="mb-4 text-start cl_description">
        <h3 className="mb-2">Course Description</h3>
        <div
          className="text-start"
          dangerouslySetInnerHTML={{ __html: description?.course_description }}
        ></div>
      </div>
    </>
  );
}

export default CategoryLearning2Description;
