import React, { useEffect, useState } from "react";
import { BlogApi } from "../APIS/MainApis";
import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ReactPaginate from "react-paginate";
import "../Style/Blog.css";

function Blog() {
  const itemsPerPage = 4;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [blogData, setBlogData] = useState([]);
  const [filterBlog, setFilterBlogs] = useState([]);

  useEffect(() => {
    async function fetchblogdata() {
      try {
        const response = await BlogApi();
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.blog_category_data
        ) {
          const allBlogs = response?.data?.data?.blog_category_data;
          setBlogData(allBlogs);
          setFilterBlogs(allBlogs?.flatMap((category) => category.posts));
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    }

    fetchblogdata();
  }, []);

  const handleNavigate = (blogId) => {
    navigate(`/blog/${blogId}`);
    window.scrollTo(0, 0);
  };

  const indexOfLastCourse = (currentPage + 1) * itemsPerPage;
  const indexOfFirstCourse = indexOfLastCourse - itemsPerPage;
  const currentblog = filterBlog?.slice(indexOfFirstCourse, indexOfLastCourse);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleBlogFilter = (id) => {
    if (id === null) {
      setSelectedCategory(null);
      setFilterBlogs(blogData?.flatMap((category) => category?.posts));
    } else {
      const filteredPosts =
        blogData.find((category) => category.id === id)?.posts || [];
      setSelectedCategory(id);
      setFilterBlogs(filteredPosts);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid mb-4 p-0 blog_container">
        <div className="row">
          <div className="col-lg-12 privacypolicy_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 privacypolicy_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">Blogs</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-4 blog_data blog_container">
        <div className="row g-5 flex-lg-row-reverse">
          <div className="col-lg-4">
            <div className="blog_categories p-3">
              <p className="text-start blog_heading">Categories</p>
              <hr />
              <p
                id="blog_category"
                className={`text-start ${
                  selectedCategory === null ? "selected" : ""
                }`}
                onClick={() => handleBlogFilter(null)}
              >
                All Blogs
              </p>
              {blogData?.map((category) => (
                <p
                  id="blog_category"
                  className={`text-start ${
                    selectedCategory === category.id ? "selected" : ""
                  }`}
                  key={category.id}
                  onClick={() => handleBlogFilter(category.id)}
                >
                  {category.name}
                </p>
              ))}
            </div>
          </div>

          <div className="col-lg-8">
            <div className="row g-5">
              {currentblog?.map((post, i) => (
                <div className="col-lg-4" key={i}>
                  <div
                    className="card blog_card"
                    onClick={() => handleNavigate(post.id)}
                  >
                    <img className="blog_image" src={post.image} alt=""></img>
                    <div className="card-body">
                      <h5 className="text-start card-title blog_title">
                        <p className="truncated-title text-start py-1">
                          {post?.name}
                        </p>
                        {/* <div className="truncated-title">
                          {post.name.length > 15
                            ? post.name.slice(0, 15) + "..."
                            : post.name}
                        </div> */}
                      </h5>
                      <p className="blogtruncated-title text-start py-1">
                        {post?.post_desc}
                      </p>
                      {/* <p className="text-start card-text blog_text mb-2">
                        {post.post_desc.length > 15
                          ? post.post_desc.slice(0, 15) + "..."
                          : post.post_desc}
                      </p> */}
                      <p className="text-start">{post.created_at}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {filterBlog?.length > itemsPerPage && (
            <ReactPaginate
              pageCount={Math.ceil(filterBlog?.length / itemsPerPage)}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active-page"}
            />
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Blog;
