import React from "react";
import { useState, useEffect } from "react";
import "../Style/ProfileSection.css";
import { ProfileUpdateApi } from "../APIS/MainApis";
import { useDispatch } from "react-redux";
import { fetchuserApi } from "../APIS/MainApis";
import { useSelector } from "react-redux";
import { setUser } from "../store/userSlice";

function ProfileSection() {
  const [updatePage, setUpdatePage] = useState(false);

  const user = useSelector((state) => state?.user?.user);

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  useEffect(() => {
    async function fetchuserData() {
      try {
        const response = await fetchuserApi(token);
        if (
          response &&
          response?.data &&
          response?.data?.data &&
          response?.data?.data?.user
        ) {
          dispatch(setUser(response?.data?.data?.user));
          setFormData({
            name: response?.data?.data?.user?.name,
            phone: response?.data?.data?.user?.phone,
            address: response?.data?.data?.user?.address,
          });
        }
      } catch (error) {
        console.log("Error fetching fetchuserData", error);
      }
    }
    fetchuserData();
  }, [token, dispatch]);

  const [profilesectionformData, setFormData] = useState({
    name: user?.name,
    phone: user?.phone,
    address: user?.address,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...profilesectionformData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateduserdata = {
      name: profilesectionformData?.name,
      address: profilesectionformData?.address,
      phone: profilesectionformData?.phone,
    };
    console.log("updateduser", updateduserdata);

    try {
      await ProfileUpdateApi(updateduserdata, token, setUpdatePage);
      const response = await fetchuserApi(token);
      console.log(response);
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.user
      ) {
        dispatch(setUser(response?.data?.data?.user));
        setFormData({
          name: response?.data?.data?.user?.name || "",
          email: response?.data?.data?.user?.email || "",
          phone: response?.data?.data?.user?.phone || "",
          address: response?.data?.data?.user?.address || "",
        });
        console.log(profilesectionformData);
      }
    } catch (error) {
      console.log("error in updating user profile", error);
    }
  };

  return (
    <>
      <div className="container p-0 mb-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="profilesection_form">
              <form onSubmit={handleSubmit}>
                <div className="form-group text-start">
                  <label>Name:</label>
                  <input
                    type="text"
                    className="form-control profilesection_formcontrol"
                    name="name"
                    value={profilesectionformData?.name}
                    onChange={handleInputChange}
                    placeholder="Name"
                  />
                </div>
                <div className="form-group text-start">
                  <label>Email Address:</label>
                  <input
                    readOnly
                    type="email"
                    className="form-control profilesection_formcontrol"
                    name="email"
                    value={user?.email}
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-group text-start">
                  <label>Phone Number:</label>
                  <input
                    type="text"
                    className="form-control profilesection_formcontrol"
                    name="phone"
                    value={profilesectionformData?.phone}
                    onChange={handleInputChange}
                    placeholder="Phone No"
                  />
                </div>
                <div className="form-group text-start">
                  <label>Address:</label>
                  <textarea
                    className="form-control profilesection_formcontrol"
                    name="address"
                    value={profilesectionformData?.address}
                    onChange={handleInputChange}
                    placeholder="Address"
                  />
                </div>
                <div className="text-start">
                  <button type="submit" className="profilesection_button">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileSection;
