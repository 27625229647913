import React from "react";
import "../Style/CookiesPolicy.css";
import Navbar from "./Navbar";
import { CookiesPolicyApi } from "../APIS/MainApis";
import { useEffect, useState } from "react";
import Footer from "./Footer";

function CookiesPolicy() {
  // const [cookiespolicyData, setcookiespolicyData] = useState([]);
  // useEffect(() => {
  //   async function fetchcookiespolicy() {
  //     const response = await CookiesPolicyApi();
  //     setcookiespolicyData(response.data.data.refund_data[0]);
  //   }
  //   fetchcookiespolicy();
  // }, []);

  return (
    <div>
      <Navbar />
      <div className="container-fluid mb-4">
        <div className="row">
          <div className="col-lg-12 cookiespolicy_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 cookiespolicy_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">CookiesPolicy</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-4 cookiespolicy_data py-4">
        <div className="row">
          <div className="col-lg-12"> 
            <h5 className="text-start mb-2">HOW WE USE COOKIES</h5>
            <p className="text-start mb-2">The European Community recently introduced legislation to protect the privacy of web users by limiting how websites can use cookies Company Name is committed to protecting your privacy. As part of this commitment, we are providing you with information about the cookies we use, so that you can make informed decisions. </p>
            <p className="text-start mb-2">The information below should be read in conjunction with our Privacy Policy and Terms and Conditions.</p>
            <h6 className="text-start mb-2">What are cookies? </h6>
            <p className="text-start mb-2">Cookies are small text files that our website(s) or services provided for us by other companies put on your computer. The information in them helps us to improve your experience of using our website and learning with us. Many of our cookies are deleted when you log out of your learning or close your web browser. None of our cookies contain any of your personally identifiable information. </p>
            <p className="text-start mb-2">Because you are using our website(s) we assume that you are happy for us to use cookies in this way. If you are not happy, then you should either not use our website(s), set your web browser to not accept cookies from Company Name or use your web browser’s anonymous browsing setting (this is called ‘Incognito’ in Google Chrome, ‘InPrivate’ in Internet Explorer and ‘Private Browsing’ in Firefox).</p>
            <p className="text-start mb-2">For more information on cookies, how to control the use of them in your browser and how to browse the internet anonymously visit the All About Cookies website.</p>
            <p className="text-start mb-2">Because our learning systems rely on cookies to deliver your courses effectively you give us consent to use cookies as outlined below when you purchase or enroll on a block (Company Name). We will not provide technical support to learners who choose to cookies and then encounter issues with their learning</p>
            <p className="text-start mb-2">You will not be able to purchase courses from the Company Name website if you set your browser to not accept cookies as our ecommerce system uses them to process transactions. We are unable to facilitate purchases by any other method (e.g., telephone sales) or provide technical support to users who choose to block Company Name cookies.</p>
            <h6 className="text-start mb-2">What cookies Company Name uses:</h6>
            <h6 className="text-start mb-2">Session Cookies </h6>
            <p className="text-start mb-2">We use a number of session cookies to provide functionality including, but not limited to, remembering who you are when you have logged into our website(s) or learning systems, to identify you when you move from one Company Name system to another, to greet you by name on screen, to optimise the effectiveness of our web servers and to remember the items you have put into our shopping basket. </p>
            <p className="text-start mb-2">We consider these cookies to be strictly necessary for the secure and efficient operation of our website(s) and learning systems and make your experience of using them easier and quicker. </p>
            <p className="text-start mb-2">If these cookies are blocked or disabled then much of the functionality of our systems will be broken. </p>
            <h6 className="text-start mb-2">Persistent Cookies</h6>
            <p className="text-start mb-2">We use a number of persistent cookies to provide functionality including, but not limited to, returning you back to the point in your learning you had reached the last time you logged out, to show you content you had looked at on a previous visit to make it easier to find it again, to make sure you are only invited to complete a web survey occasionally and that you don’t see alerts or messages repeatedly during a single web visit.</p>
            <p className="text-start mb-2">If these cookies are blocked or disabled then our systems will still be usable, but the user experience will be severely degraded. </p>
            <h6 className="text-start mb-2">DoubleClick (Google) </h6>
            <p className="text-start mb-2">This cookie is used on a small number of pages on the Company Name website to track the effectiveness of our Google AdWords’ and Google Sponsored Link advertisin</p>
            {/* <p className="text-start mb-2">For more information on how to permanently opt-out of DoubleClick placing this cookie on your computer visit <a>http://www.google.com/policies/privacy/ads/#toc-optout</a>or install the Google Advertising Cookie Plugin for your browserhttp://www.google.com/ads/preferences/plugin/ </p> */}
            <h6 className="text-start mb-2">Social network cookies </h6>
            <p className="text-start mb-2">Some pages on our website(s) contain social network share or like buttons. The social network may set a cookie (or cookies) on your PC when you click on one of these buttons. </p>
            <p className="text-start mb-2">In order to implement these buttons on our website(s) we use code which is hosted by the relevant social network. If you are a member of some of these networks, they may use this code to track that you have visited our website(s) whether you click the buttons or not. </p>
            <p className="text-start mb-2">The Safety and Training Academy does not control these cookies or the underlying code. You should check the privacy statement of the relevant social network before clicking them and/or to find out how they track their members’ behaviour using these elements and how to opt out of this functionality </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default CookiesPolicy;
