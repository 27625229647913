import React, { useState } from "react";
import "../Style/CategoryComponent.css";
import { useNavigate } from "react-router-dom";
import { addToWishlistApi } from "../APIS/MainApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StarRatings from "react-star-ratings";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

function CategoryComponent({ courses }) {
  const token = localStorage.getItem("token");
  const user = useSelector((state) => state?.user?.user);

  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(0);

  const indexOfLastCourse = (currentPage + 1) * itemsPerPage;
  const indexOfFirstCourse = indexOfLastCourse - itemsPerPage;
  const currentCourses = courses?.slice(indexOfFirstCourse, indexOfLastCourse);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const navigate = useNavigate();
  const handleNavigate = (courseId) => {
    navigate(`/singlecategory/${courseId}`);
    window.scrollTo(0, 0);
  };

  const addToWishlist = async (id) => {
    if (user === null) {
      toast.error("Login to continue");
      return;
    }
    const wishlist = {
      course_id: id,
    };
    const response = await addToWishlistApi(wishlist, token);
    toast.dismiss();
    if (
      response &&
      response.data &&
      response.data.responses[0] &&
      response.data.responses[0].message
    ) {
      toast.success(response?.data?.responses[0]?.message);
    } else {
      toast.error("Failed to add product to cart");
    }
  };

  return (
    <>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4">
        {currentCourses?.length === 0 ? (
          <>
            <p style={{ width: "100%" }} className="mt-5">
              No courses to display
            </p>
          </>
        ) : (
          <>
            {currentCourses?.map((course, i) => (
              <div className="col gy-3" key={i}>
                <div className="card">
                  <img
                    src={course?.image}
                    className="categorycomponent_image"
                    alt=""
                  />
                  <div
                    className="card-body"
                    onClick={() => handleNavigate(course.id)}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="level">{course?.level}</span>
                      <h5 className="price">&#163;{course?.actual_price}</h5>
                    </div>

                    <p className="truncated-title text-start py-2">
                      {course?.name}
                    </p>

                    <div className="d-flex mb-2 whom">By: {course?.author}</div>
                    <div className="text-start category_span">
                      <div className="d-flex align-items-center">
                        <span className="categorycomponent_avgrating">
                          {parseFloat(course?.avgRating)}
                        </span>
                        <span className="comments">({course?.totalUser})</span>
                      </div>
                      <div>
                        <StarRatings
                          rating={parseFloat(course?.avgRating)}
                          starRatedColor="#f69c08"
                          starEmptyColor="#E0E0E0"
                          numberOfStars={5}
                          starDimension="13px"
                          starSpacing="2px"
                          name="rating"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between align-items-center">
                    <div>
                      <button
                        className="enroll"
                        onClick={() => handleNavigate(course.id)}
                      >
                        View Details
                      </button>
                    </div>
                    <div>
                      <button
                        className="enroll"
                        onClick={() => addToWishlist(course.id)}
                      >
                        Add to Wishlist
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="categorycomponent_paginate">
        {courses?.length > itemsPerPage && (
          <ReactPaginate
            pageCount={Math.ceil(courses?.length / itemsPerPage)}
            pageRangeDisplayed={0}
            marginPagesDisplayed={0}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active-page"}
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={<span className="ellipsis">...</span>}
            breakClassName={"break-me"}
            disabledClassName={"disabled"}
            initialPage={currentPage}
            disableInitialCallback={true}
          />
        )}
      </div>
      <ToastContainer />
    </>
  );
}

export default CategoryComponent;
