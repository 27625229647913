import React from "react";
import "../Style/Footer.css";
import { BiLogoFacebook } from "react-icons/bi";
import { AiOutlineInstagram } from "react-icons/ai";
import { LiaTwitter } from "react-icons/lia";
import { AiOutlineYoutube } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { fetchAdminContactDetails } from "../APIS/MainApis";
import { useState, useEffect } from "react";

function Footer() {
  const [admindata, setAdminData] = useState([]);

  const navigate = useNavigate();
  const AboutUsNavigate = () => {
    navigate("/aboutus");
    window.scrollTo(0, 0);
  };
  const ContactUsNavigate = () => {
    navigate("/contactus");
    window.scrollTo(0, 0);
  };
  const FaqNavigate = () => {
    navigate("/faq");
    window.scrollTo(0, 0);
  };
  const BlogNavigate = () => {
    navigate("/blog");
    window.scrollTo(0, 0);
  };
  const PrivacyPolicyNavigate = () => {
    navigate("/privacypolicy");
    window.scrollTo(0, 0);
  };
  const DataPolicyNavigate = () => {
    navigate("/datapolicy");
    window.scrollTo(0, 0);
  };
  const CookiesPolicyNavigate = () => {
    navigate("/cookiespolicy");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    async function fetchadmindata() {
      const response = await fetchAdminContactDetails();
      if (response?.data?.data?.contact_data?.length > 0) {
        setAdminData(response.data.data.contact_data[0]);
      }
    }
    fetchadmindata();
  }, []);

  return (
    <>
      <div className="footer py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 ">
              <div className="text-start mb-2">
                <img
                  src="../company_white_logo.png"
                  height="40"
                  width="150"
                  alt=""
                ></img>
              </div>
              <div className="text-start mb-2">
                <p className="text-white footer_left_text">
                  The Safety and Training Learning Academy provide courses like
                  workplace safety training, compliance essentials, safety
                  leadership, behavioural safety and psychology, IOSH Managing
                  Safely, Risk Assessment, First Aid and CPR, Fire Risk
                  Assessment, Fire Safety, Confined Space Entry, Authorised Gas
                  Tester and much more
                </p>
              </div>
              <div className="text-start d-flex align-items-center">
                <span className="social_icons">
                  <BiLogoFacebook />
                </span>
                <span className="social_icons mx-1">
                  <AiOutlineInstagram />
                </span>
                <span className="social_icons mx-1">
                  <LiaTwitter />
                </span>
                <span className="social_icons mx-1">
                  <AiOutlineYoutube />
                </span>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 mt-xxl-0  mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-5">
              <div className="mb-2 text-start font-weight-bold footer_headings">
                Company
              </div>
              <div className="text-start footer_headings_content">
                <ul className="m-0 p-0">
                  <li onClick={AboutUsNavigate}>About Us</li>
                  <li onClick={BlogNavigate}>Blogs</li>
                  <li onClick={CookiesPolicyNavigate}>Cookies Policy</li>
                  <li onClick={PrivacyPolicyNavigate}>Privacy Policy</li>
                  <li onClick={DataPolicyNavigate}>Data Policy</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 mt-xxl-0  mt-xl-0 mt-lg-0 mt-md-5 mt-sm-5 mt-5">
              <div className="mb-2 text-start font-weight-bold footer_headings">
                Support
              </div>
              <div className="text-start footer_headings_content">
                <ul className="m-0 p-0">
                  {/* <li>Help Support</li> */}
                  <li onClick={FaqNavigate}>FAQ's</li>
                  <li onClick={ContactUsNavigate}>Contact Us</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-xxl-0  mt-xl-0 mt-lg-0 mt-md-5 mt-sm-5 mt-5">
              <div className="mb-2 text-start font-weight-bold footer_headings">
                Get In Touch
              </div>
              <div className="text-start">
                <p className="text-white">Address: {admindata.address}</p>
                <p className="text-white">Email: {admindata.email}</p>
                <p className="text-white">Phone No: +44-0800-009-6199</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
