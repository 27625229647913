import React, { useEffect, useState } from "react";
import "../Style/ContentsMenu.css";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { RxVideo } from "react-icons/rx";
import Footer from "../Components/Footer";
import { fetchSingleCategoryApi } from "../APIS/MainApis";
import { useLocation } from "react-router-dom";
import ContentsMenuNavbar from "../Components/ContentsMenuNavbar";
import { LessonprogressApi } from "../APIS/MainApis";
import { courseprogressApi } from "../APIS/MainApis";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function ContentsMenu() {
  const location = useLocation();
  const courseId = location.state ? location?.state?.courseId : null;

  const [courseData, setCourseData] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [courseProgress, setCourseProgress] = useState(0);

  const savedUser = localStorage.getItem("user");
  const user = savedUser ? JSON.parse(savedUser) : null;
  const userId = user ? user?.user?.id : null;

  useEffect(() => {
    async function fetchCourse() {
      const contentmenu_data = await fetchSingleCategoryApi(courseId);

      if (
        contentmenu_data &&
        contentmenu_data[0] &&
        contentmenu_data[0]?.sections &&
        contentmenu_data[0]?.sections[0]?.lessons[0]
      ) {
        setCourseData(contentmenu_data[0].sections);
        setSelectedLesson(contentmenu_data[0].sections[0].lessons[0]);
      }
    }

    fetchCourse();
  }, [courseId]);

  useEffect(() => {
    async function courseprogress() {
      const data = {
        userId: userId,
        courseId: courseId,
      };
      const response = await courseprogressApi(data);
      console.log(response);

      if (response && response.data && response.data.data) {
        setCourseProgress(response.data.data.average_percentage);
      }
    }

    courseprogress();
  }, [courseId, userId]);

  const handleVideoEnd = () => {
    if (selectedLesson) {
      async function fetchlessonData() {
        const userdata = {
          lessonId: selectedLesson.lesson_id,
          userId: userId,
          progressPercentage: 100,
          courseId: courseId,
        };
        const data = {
          userId: userId,
          courseId: courseId,
        };
        console.log(userdata);
        try {
          await LessonprogressApi(userdata);
          // const { course_progress } = response.data;
          // setCourseProgress(course_progress);
          const response = await courseprogressApi(data);
          setCourseProgress(response.data.data.average_percentage);
        } catch (error) {
          console.error("Error fetching lesson data:", error);
        }
      }
      fetchlessonData();
    }
  };

  return (
    <div>
      <ContentsMenuNavbar />
      <div className="container-fluid py-5">
        <div className="row  flex-lg-row-reverse">
          <div className="col-lg-9 backgrounds">
            <div className="mt-3 mb-3 d-flex align-items-center justify-content-between w-100">
              <div className="text-start left fw-bold d-flex align-items-center">
                {selectedLesson ? selectedLesson.lesson_title : "Select Lesson"}
              </div>
              <div className="right fw-bold">
                <div className="contentsmenu_progress">
                  <CircularProgressbar
                    value={courseProgress}
                    text={`${courseProgress}%`}
                    styles={buildStyles({
                      strokeLinecap: "round",
                      textSize: "30px",
                      pathTransitionDuration: 0.5,
                      pathColor: `rgba(255,0,0, ${courseProgress / 100})`,
                      textColor: "white",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
            </div>

            <div>
              {selectedLesson && (
                <video
                  width="100%"
                  height="100%"
                  controls
                  onEnded={handleVideoEnd}
                >
                  <source src="../video.mp4" type="video/mp4" />
                </video>
              )}
            </div>
          </div>

          <div className="col-lg-3 p-0">
            <div className="fw-bold contentmenu_table text-start">
              Table of content
            </div>
            <div className="accordian_container">
              <MDBAccordion>
                {courseData?.map((coursedata, i) => (
                  <MDBAccordionItem
                    key={i}
                    collapseId={i + 1}
                    headerTitle={coursedata.section_title}
                  >
                    {coursedata.lessons.map((lesson, j) => (
                      <div
                        key={j}
                        className="d-flex justify-content-between align-items-center mb-2"
                        onClick={() => setSelectedLesson(lesson)}
                      >
                        <div className="cmcontent_menu d-flex text-start align-items-center">
                          <div className="contentsmenu_videoicon">
                            <RxVideo />
                          </div>
                          <div className="contentsmenu_title">
                            {lesson.lesson_title}
                          </div>
                        </div>
                        <div className="contentmenu_time">1m 17s</div>
                      </div>
                    ))}
                  </MDBAccordionItem>
                ))}
              </MDBAccordion>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContentsMenu;
