import React from "react";
import "../Style/OnlineClasses.css";
import { fetchNewCoursesApi } from "../APIS/MainApis";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function OnlineClasses() {
  const [newCourses, setNewCourses] = useState([]);

  let payload = {
    meetingNumber: "",
    role: 0,
    sdkKey: "",
    sdkSecret: "",
    passWord: "",
    userName: "Testing",
    userEmail: "",
    leaveUrl: "https://localhost:3000",
  };
  useEffect(() => {
    async function fetchnewcourses() {
      const response = await fetchNewCoursesApi();
      if (response?.data?.data?.new_course_data) {
        setNewCourses(response.data.data.new_course_data);
      }
    }
    fetchnewcourses();
  }, []);

  const navigate = useNavigate();
  const handleNavigate = (courseId) => {
    navigate(`/singlecategory/${courseId}`);
    window.scrollTo(0, 0);
  };

  // const handlejoinclass=()=>{
  //   navigate("/meeting");
  // }

  return (
    <>
      <div className="online-classes">
        <div className="container py-5 online-classes">
          <div className="mb-5">
            <h3 className="text-center fw-bold">
              Our New <span className="special">Courses</span>
            </h3>
            <p className="text-center mb-4">
              These Are Three New Courses Which We Have Added Recently
            </p>
          </div>
          <div className="row g-5">
            {newCourses.map((newcourses, i) => (
              <div className="col-12 col-lg-6 col-xl-4 col-xxl-4" key={i}>
                <div
                  className="card onlineclass_card p-2"
                  onClick={() => handleNavigate(newcourses.id)}
                >
                  <img
                    src={newcourses.course_image}
                    className="card-img-top onlineclasses_image"
                    alt="..."
                  ></img>
                  <div className="onlineclass_card-body py-4">
                    <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
                      <div className="online-text-grey">
                        {newcourses.course_level}
                      </div>
                    </div>
                    <p className="truncated-title text-start py-1">
                      {newcourses.title}
                    </p>
                    <p className="online-card-text text-start">
                      &#163;{newcourses.discountPrice}
                    </p>
                    <hr></hr>
                    <button className="online-button text-white fw-bold px-4 py-2 rounded-5">
                      View
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default OnlineClasses;
