import React from "react";
import "../Style/CategoryLearning2Right1.css";
import { add } from "../store/cartSlice";
import { useDispatch } from "react-redux";
import { AddToCartCartApi } from "../APIS/MainApis";
import { fetchCartApi } from "../APIS/MainApis";
import { addToWishlistApi } from "../APIS/MainApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

function CategoryLearning2Right1({ product }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const token = localStorage.getItem("token");

  const addToWishlist = async (id) => {
    if (user === null) {
      toast.error("Login to continue");
      return;
    }
    const wishlist = {
      course_id: id,
    };
    const response = await addToWishlistApi(wishlist, token);
    toast.dismiss();
    if (response) {
      toast.success(response?.data?.responses[0]?.message);
    } else {
      toast.error("Failed to add product to cart");
    }
  };

  const handleAddToCart = async () => {
    try {
      if (user === null) {
        dispatch(add(product));
        return;
      } else {
        const response = await AddToCartCartApi(token, [product.id]);
        if (
          response?.data?.responses[0]?.message ===
          "Course is already purchased by the user."
        ) {
          toast.dismiss();
          const messageFromApi = response?.data?.responses[0]?.message;
          toast.success(messageFromApi);
          return;
        }

        const messageFromApi = response?.data?.responses[0]?.message;
        toast.dismiss();

        if (response) {
          toast.success(messageFromApi);
        } else {
          toast.error("Failed to add product to cart");
        }

        fetchCartApi(token, (data) => {
          const response2 = data;
          for (const item of response2?.data?.cartItems) {
            dispatch(add(item));
          }
        });
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  return (
    <div className="cl_card2 p-3 mt-3">
      <div className="text-center">
        <video className="cl_righ1_demo_video" controls>
          <source src="../video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="cl_card-body">
        <div className="text-start mb-2">
          <span className="cl_sp">${product?.courseDiscount_price}</span>{" "}
          <span className="cl_cp">${product?.courseActual_price}</span>
        </div>

        <div className="d-flex flex-column align-items-start justify-content-center">
          <button
            onClick={() => handleAddToCart()}
            className="cl_button1 mb-2 rounded-1 py-2"
          >
            Add To Cart
          </button>
          <button
            className="cl_button2 rounded-1 py-2"
            onClick={() => addToWishlist(product.id)}
          >
            Add To Wishlist
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CategoryLearning2Right1;
