import store from "../src/store/store";
import "./App.css";
import { setUser } from "./store/userSlice";
import { fetchuserApi } from "./APIS/MainApis";
import { fetchCartApi } from "./APIS/MainApis";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { add } from "./store/cartSlice";
import Route from "./Route";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchuserData() {
      try {
        const response = await fetchuserApi(token);
        console.log(response.data.data);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.user
        ) {
          store.dispatch(setUser(response.data.data.user));
        }
        else{
          localStorage.setItem("userislogin", false);
        }
      } catch (error) {
        console.log("Error fetching fetchuserData", error);
      }
    }
    fetchuserData();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      return;
    }
    async function fetchCartData() {
      try {
        fetchCartApi(token, (data) => {
          const response = data;
          for (const item of response.data.cartItems) {
            dispatch(add(item));
          }
        });
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    }
    fetchCartData();
  }, []);

  return (
    <div className="App">
      <Route />
    </div>
  );
}

export default App;
