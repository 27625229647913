import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import CategoryDescription from "../Components/CategoryDescription";
import CategoryLearning2Right1 from "./CategoryLearning2Right1";
import CategoryLearning2Right2 from "./CategoryLearning2Right2";
import CategoriesTab from "../Layouts/CategoriesTab";
import CategoryLearning2Content from "./CategoryLearning2Content";
import CategoryLearning2Description from "./CategoryLearning2Description";
import CategoryLearning2Reviews from "./CategoryLearning2Reviews";
import "../Style/SingleCategory.css";
import { fetchSingleCategoryApi } from "../APIS/MainApis";
import { useParams } from "react-router-dom";
import Footer from "../Components/Footer";



function SingleCategory() {
  const params = useParams();
  const [activeTab, setActiveTab] = useState("content");
  const [courseData, setCourseData] = useState({});

  useEffect(() => {
    async function fetchCourse(){
      const content_data = await fetchSingleCategoryApi(params.courseId);
      if (content_data && content_data.length > 0) {
        setCourseData(content_data[0]);
      }
    }

    fetchCourse();
  }, [params.courseId]); 

  return (
    <div className="page-container">
      <Navbar />
      <CategoryDescription about={courseData}/>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 col-lg-8 sc_container">
            <CategoriesTab setActiveTab={setActiveTab} activeTab={activeTab} />
            {activeTab === "content" ? (
              <CategoryLearning2Content
                sections={courseData.sections || []}
                id={params.courseId}
              />
            ) : null}

            {activeTab === "description" ? (
              <CategoryLearning2Description description={courseData} />
            ) : null}

            {activeTab === "reviews" ? <CategoryLearning2Reviews about={courseData} /> : null}
          </div>
          <div className="col-12 col-lg-4">
            <div className="col-12 col-lg-12 mb-4 p-0">
              <CategoryLearning2Right1 product={courseData}/>
            </div>
            {/* <div className="col-12 col-lg-12 p-0">
              <CategoryLearning2Right2 />
            </div> */}
          </div> 
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default SingleCategory;
