const { createSlice } = require("@reduxjs/toolkit");




const initialCartState = {
  items: JSON.parse(localStorage.getItem("cart")) || [],
};

const cartSlice = createSlice({ 
  name: "cart",
  initialState: initialCartState,
  reducers: { 
    add(state, action) { 
      const existingProduct = state.items.find((item) => item.id === action.payload.id);
      if (!existingProduct) {
        const newItems = [...state.items, action.payload];
        const userislogin= localStorage.getItem("userislogin");
        console.log(userislogin)
        if (userislogin==="false") {
          localStorage.setItem("cart", JSON.stringify(newItems));
        }
        return {
          ...state, 
          items: newItems, 
        }; 
      }
      return state;
    },

    remove(state, action) {
      const updatedCart = state.items.filter((item) => item.id !== action.payload);
      const userislogin = localStorage.getItem("userislogin");
      console.log(userislogin)
      if (userislogin==="false") {
        localStorage.setItem("cart", JSON.stringify(updatedCart));
      } 
      return {
        ...state,
        items: updatedCart,
      };
      
    },

    clearcart(state) {
      localStorage.removeItem("cart");
      return {
        ...state,
        items: [],
      };
    },
  },
});

export const { add, remove, clearcart,notlogin} = cartSlice.actions;
export default cartSlice.reducer;
