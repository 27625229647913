import React from "react";
import "../Style/Classes.css";
import { fetchTopCoursesApi } from "../APIS/MainApis";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Classes() { 

  const [topCourses, setTopCourses] = useState([]); 


  const navigate = useNavigate();
  const handleNavigate = (courseId) => {
    navigate(`/singlecategory/${courseId}`);
    window.scrollTo(0, 0);
  };

  
  useEffect(() => {
    async function fetchtopcourses() {
      try{ 
        const response = await fetchTopCoursesApi();
        if (response && response.data && response.data.data) {
          setTopCourses(response?.data?.data?.top_course_data);
        }
      }catch(error)
      {
        console.log("error fetching top courses",error)
      }
    }
    fetchtopcourses();
  }, []); 


  return ( 
    <>
      <div className="class-container">   
        <div className="container py-5"> 
          <div className="mb-5">
            <h3 className="class-heading1 fw-bold text-center"> 
              Our Top Courses
            </h3>
            <p className="class-heading2 text-center mb-4">
              These are our top four courses  
            </p> 
          </div> 

          <div className="row mb-5 g-5">
            {topCourses?.map((topcourses, i) => (
              <div className="col-12 col-lg-6 col-xl-3 col-xxl-3" key={i}>
                <div className="class_card" onClick={() => handleNavigate(topcourses.id)}>
                  <div className="p-2">
                  <img src={topcourses?.course_image} className="card-img-top classes_image" alt="..."></img>
                  </div>
                  <div className="classes_card-body p-2">
                  <p className="truncated-title text-start py-1">
                      {topcourses?.title}
                    </p>
                    <p className="text-start classes-card-text mb-1"> 
                      {topcourses?.course_level}
                    </p>
                    <p className="text-start classes-card-text mb-1">
                      By : {topcourses?.course_author}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button className="class-button px-3 py-1" onClick={()=>navigate("/allcourses")} >See All Courses</button>
        </div>
      </div>
    </>
  );
}

export default Classes;
