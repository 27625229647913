import React from "react";
import { Modal, Button } from "react-bootstrap";
import OTPInput from "otp-input-react";
import { useState } from "react";
import { validateOTPApi } from "../APIS/MainApis";
import "../Style/Otp.css";
import Swal from "sweetalert2";
import NewPassword from "../Components/NewPassword";

function Otp({ openOtpModal, setOtpModal }) {
  const [OTP, setOTP] = useState("");
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [newPasswordModal, setnewPasswordModal] = useState(false);
  const [token, setToken] = useState("");

  const handleVerifyOTP = async () => {
    const response = await validateOTPApi(OTP);

    if (response.data.status === "success") { 
      Swal.fire({
        icon: "success",
        title: "Otp Verified",
        text: response.data.message,
      });
      setToken(response.data.data.token);
      setOTP("");
      setOtpModal(false);
      setVerificationSuccess(true);
      setnewPasswordModal(true);
    } else {
      setOTP("");
      setOtpModal(false);
      Swal.fire({
        icon: "error",
        title: "Otp Invalid",
        text: response.data.message,
      });
    }
  };

  return (
    <>
      {verificationSuccess && (
        <NewPassword
          newPasswordModal={newPasswordModal}
          setnewPasswordModal={setnewPasswordModal}
          token={token}
        />
      )}
      <Modal show={openOtpModal} onHide={() => setOtpModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="otp_title">Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="otp_verify_button" onClick={handleVerifyOTP}>
            Verify OTP
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Otp;
