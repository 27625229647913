import React from "react";
import "../Style/PrivacyPolicy.css";
import Navbar from "../Components/Navbar";
// import { TermsAndConditionApi } from "../APIS/MainApis";
// import { useEffect, useState } from "react";
import Footer from "./Footer";

function PrivacyPolicy() {
  // const [termsandconditionData, settermsandconditionData] = useState([]);

  // useEffect(() => {
  //   async function fetchtermsandcondition() {
  //     const response = await TermsAndConditionApi();
  //     if ( 
  //       response &&
  //       response.data &&
  //       response.data.data &&
  //       response.data.data.terms_data[0]
  //     ) {
  //       settermsandconditionData(response.data.data.terms_data[0]);
  //     }
  //   }
  //   fetchtermsandcondition();
  // }, []);

  return (
    <div>
      <Navbar />
      <div className="container-fluid  p-0 privacypolicy_container">
        <div className="row">
          <div className="col-lg-12 termscondition_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 termscondition_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">Privacy Policy</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container p-5 privacypolicy_container">
        <div className="row">
          <div className="col--12">
            <h1 className="text-start mb-2">Privacy Notice</h1>
            <p className="text-start mb-2">
              We are 'The Safety and Training Academy', a company registered in
              England and Wales with company number 14539248, whose registered
              address is at 128 City Road, London EC1V 2NX. In this privacy
              notice, we will refer to ourselves as 'we', 'us', or 'our'.
            </p>

            <p className="text-start mb-2">
              You can get hold of us in any of the following ways:
            </p>
            <ul className="text-start mb-2 p-0">
              <li>By phoning us on 0208 144 5897</li>
              <li>
                By e-mailing us at{" "}
                <a href="mailto:contact@thesafetytrainingcompany.com" className="privacypolicy_mail">
                  contact@thesafetytrainingcompany.com
                </a>
              </li>
              <li>
                By writing to us at The Safety and Training Academy Ltd, 128
                City Road, London EC1V 2NX.
              </li>
            </ul>

            <p className="text-start mb-2">
              We take the privacy, including the security, of personal
              information we hold about you seriously. This privacy notice is
              designed to inform you about how we collect personal information
              about you and how we use that personal information. You should
              read this privacy notice carefully so that you know and can
              understand why and how we use the personal information we collect
              and hold about you.
            </p>

            <p className="text-start mb-2">
              We have appointed Wayne M Sundborg as our Data Protection Officer.
              You can contact them using the details set out above.
            </p>

            <p className="text-start mb-2">
              We may issue you with other privacy notices from time to time,
              including when we collect personal information from you. This
              privacy notice is intended to supplement these and does not
              override them.
            </p>

            <p className="text-start mb-2">
              We may update this privacy notice from time to time. This version
              was last updated on 01 June 2023.
            </p>

            <h2 className="text-start mb-2">1. Key Definitions</h2>
            <p className="text-start mb-2">
              The key terms that we use throughout this privacy notice are
              defined below, for ease:
            </p>
            <ul className="text-start mb-2">
              <li>
                <strong>Data Controller:</strong> under UK data protection law,
                this is the organisation or person responsible for deciding how
                personal information is collected and stored and how it is used.
              </li>
              <li>
                <strong>Data Processor:</strong> a Data Controller may appoint
                another organisation or person to carry out certain tasks in
                relation to the personal information on behalf of, and on the
                written instructions of, the Data Controller. (This might be the
                hosting of a site containing personal data, for example, or
                providing an email marketing service that facilitates mass
                distribution of marketing material to a Data Controller’s
                customer-base.)
              </li>
              <li>
                <strong>Personal Information:</strong> in this privacy notice we
                refer to your personal data as 'personal information'. 'Personal
                information' means any information from which a living
                individual can be identified. It does not apply to information
                which has been anonymized.
              </li>
              <li>
                <strong>Special Information:</strong> certain very sensitive
                personal information requires extra protection under data
                protection law. Sensitive data includes information relating to
                health, racial and ethnic origin, political opinions, religious
                and similar beliefs, trade union membership, sex life and sexual
                orientation and also includes genetic information and biometric
                information.
              </li>
            </ul>

            <h2 className="text-start mb-2">
              2. Details of personal information which we collect and hold about
              you
            </h2>
            <p className="text-start mb-2">
              2.1 Set out below are the general categories and details of
              retention periods in relation to those categories (see section 8
              below for more details about retention) and in each case the types
              of personal information which we collect, use and hold about you:
            </p>

            <div className="table-responsive">
              <table className="table termscondition_table mb-2">
                <thead>
                  <tr>
                    <th>General Category</th>
                    <th>Types of Personal Data in that category</th>
                    <th>Retention Periods</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Identity Information</td>
                    <td>
                      This is information relating to your identity such as your
                      name (including any previous names and any titles which
                      you use), gender, marital status and date of birth
                    </td>
                    <td>6 years</td>
                  </tr>
                  <tr>
                    <td>Contact Information</td>
                    <td>
                      This is information relating to your contact details such
                      as e-mail address, addresses, telephone numbers
                    </td>
                    <td>6 years</td>
                  </tr>
                  <tr>
                    <td>[Account Information]</td>
                    <td>
                      [This is information relating to your account with us
                      (including username and password)]
                    </td>
                    <td>2 years</td>
                  </tr>
                  <tr>
                    <td>[Payment Information]</td>
                    <td>
                      [This is information relating to the methods by which you
                      provide payment to us such as [bank account details,
                      credit or debit card details] and details of any payments
                      (including amounts and dates) which are made between us.]
                    </td>
                    <td>2 years</td>
                  </tr>
                  <tr>
                    <td>[Transaction Information]</td>
                    <td>
                      [This is information relating to transactions between us
                      such as details of the goods, services and/or digital
                      content provided to you and any returns details.]
                    </td>
                    <td>2 years</td>
                  </tr>
                  <tr>
                    <td>[Survey Information]</td>
                    <td>
                      [This is information which we have collected from you or
                      which you have provided to us in respect of surveys and
                      feedback.]
                    </td>
                    <td>6 years</td>
                  </tr>
                  <tr>
                    <td>[Marketing Information]</td>
                    <td>
                      [This is information relating to your marketing and
                      communications preferences.]
                    </td>
                    <td>6 years</td>
                  </tr>
                  <tr>
                    <td>[Website, Device and Technical Information]</td>
                    <td>
                      [This is information about your use of our website and
                      technical data which we collect (including your IP
                      address, the type of browser you are using and the
                      version, the operating system you are using, details about
                      the time zone and location settings on the device and
                      other information we receive about your device)]
                    </td>
                    <td>6 years</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h2 className="text-start mb-2">
              3. Details of special information which we collect and hold about
              you
            </h2>
            <p className="text-start mb-2">
              3.1 We do not collect or hold any special information about you.
            </p>
            <p className="text-start mb-2">
              3.2 We do not collect information from you relating to criminal
              convictions or offences.
            </p>

            <h2 className="text-start mb-2">
              4. Details of how and why we use personal information
            </h2>

            <p className="text-start mb-2">
              4.1 We are only able to use your personal information for certain
              legal reasons set out in data protection law. There are legal
              reasons under data protection law other than those listed below,
              but in most cases, we will use your personal information for the
              following legal reasons:
            </p>

            <ul className="text-start mb-2">
              <li>
                (a) <strong>Contract Reason</strong>: this is in order to
                perform our obligations to you under a contract we have entered
                into with you;
              </li>
              <li>
                (b) <strong>Legitimate Interests Reason</strong>: this is where
                the use of your personal information is necessary for our (or a
                third party’s) legitimate interests, so long as that legitimate
                interest does not override your fundamental rights, freedoms or
                interests;
              </li>
              <li>
                (c) <strong>Legal Obligation Reason</strong>: this is where we
                have to use your personal information in order to perform a
                legal obligation by which we are bound; and
              </li>
              <li>
                (d) <strong>Consent Reason</strong>: this is where you have
                given us your consent to use your personal information for a
                specific reason or specific reasons.
              </li>
            </ul>

            <p className="text-start mb-2">
              4.2 As explained in section 3 above, there are more sensitive
              types of personal data which require higher levels of protection.
              Where we process such sensitive types of personal data, we will
              usually do this in the following circumstances:
            </p>

            <ul className="text-start mb-2">
              <li>(a) We have your explicit consent;</li>
              <li>(b) Where it is necessary in relation to legal claims;</li>
              <li>(c) Where you have made the personal data public.</li>
            </ul>

            <p className="text-start mb-2">
              4.3 So that we are able to provide you with services, we will need
              your personal information. If you do not provide us with the
              required personal information, we may be prevented from supplying
              the services to you.
            </p>

            <p className="text-start mb-2">
              4.4 It is important that you keep your personal information up to
              date. If any of your personal information changes, please contact
              us as soon as possible to let us know. If you do not do this then
              we may be prevented from supplying the services to you (for
              example, if you move address and do not tell us, then your
              certificates may be delivered to the wrong address).
            </p>

            <p className="text-start mb-2">
              4.5 Where we rely on consent for a specific purpose as the legal
              reason for processing your personal information, you have the
              right under data protection law to withdraw your consent at any
              time. If you do wish to withdraw your consent, please contact us
              using the details set out at the beginning of this notice. If we
              receive a request from you withdrawing your consent to a specific
              purpose, we will stop processing your personal information for
              that purpose unless we have another legal reason for processing
              your personal information, in which case, we will confirm that
              reason to you.
            </p>

            <p className="text-start mb-2">
              4.6 We have explained below the different purposes for which we
              use your personal information and, in each case, the legal
              reason(s) allowing us to use your personal information. Please
              also note the following:
            </p>

            <ul className="text-start mb-2">
              <li>
                (a) if we use the Legitimate Interests Reason as the legal
                reason for which we can use your personal information, we have
                also explained what that legitimate interest is;
              </li>
              <li>
                (b) for some of the purposes we may have listed more than one
                legal reason on which we can use your personal information,
                because the legal reason may be different in different
                circumstances. If you need confirmation of the specific legal
                reason that we are relying on to use your personal data for that
                purpose, please contact us using the contact details set out at
                the start of this privacy notice.
              </li>
            </ul>

            <div className="table-responsive">
              <table className="table termscondition_table mb-2">
                <thead>
                  <tr>
                    <th>Purpose</th>
                    <th>Legal Reason(s) for using the personal information</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>To enrol you as a customer/ candidate</td>
                    <td>
                      Contract Reason. Legitimate Interests Reason (in order to
                      offer you other services and/or digital content which
                      helps us to develop our business)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      To process your order, which includes taking payment from
                      you, advising you of any updates in relation to your order
                      or any enforcement action against you to recover payment.
                    </td>
                    <td>
                      Contract Reason. Legitimate Interests Reason: in order to
                      recover money which, you may owe us.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      To manage our contract with you and to notify you of any
                      changes
                    </td>
                    <td>Contract Reason. Legal Obligation Reason.</td>
                  </tr>
                  <tr>
                    <td>To comply with audit and accounting matters</td>
                    <td>Legal Obligation Reason</td>
                  </tr>
                  <tr>
                    <td>
                      For record keeping, including in relation to any
                      guarantees or warranties provided as part of the sale of
                      services and/or digital content
                    </td>
                    <td>Contract Reason. Legal Obligation Reason.</td>
                  </tr>
                  <tr>
                    <td>
                      To improve the services, and/or digital content which we
                      supply
                    </td>
                    <td>
                      Legitimate Interests Reason in order to improve the
                      services, and/or digital content for future customers and
                      to grow our business
                    </td>
                  </tr>
                  <tr>
                    <td>
                      To recommend and send communications to you about goods,
                      services, and/or digital content that you may be
                      interested in. More details about marketing are set out in
                      section 11 below
                    </td>
                    <td>
                      Legitimate Interests Reason in order to grow our business
                    </td>
                  </tr>
                  <tr>
                    <td>
                      To ensure the smooth running and correct operation of our
                      website and business
                    </td>
                    <td>
                      Legitimate Interests Reason (to ensure our business and
                      website runs correctly
                    </td>
                  </tr>

                  <tr>
                    <td>
                      To understand how customers and visitors to our website
                      use the website and interact with it via data analysis
                    </td>
                    <td>
                      Legitimate Interests Reason to improve and grow our
                      business, including our website, and to understand our
                      customer’s needs, desires and requirements
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="text-start mb-2">
              4.7 Sometimes we may anonymize personal information so that you
              can no longer be identified from it and use this for our own
              purposes. In addition, sometimes we may use some of your personal
              information together with other people’s personal information to
              give us statistical information for our own purposes. Because this
              is grouped together with other personal information and you are
              not identifiable from that combined data, we are able to use this.
            </p>

            <p className="text-start mb-2">
              4.8 Under data protection laws we can only use your personal
              information for the purposes we have told you about, unless we
              consider that the new purpose is compatible with the purpose(s)
              which we told you about. If we want to use your personal
              information for a different purpose which we do not think is
              compatible with the purpose(s) which we told you about then we
              will contact you to explain this and what legal reason is in place
              to allow us to do this.
            </p>

            <h2 className="text-start mb-2">
              5. Details of how we collect personal information and special
              information
            </h2>
            <p className="text-start mb-2">
              5.1 We usually collect Identity Information, Contact Information,
              Payment Information, Transaction Information, Survey Information,
              Marketing Information; directly from you when you fill out a form,
              survey or questionnaire, purchase services and/or digital content
              from us, contact us by e-mail, telephone, in writing or otherwise.
              This includes the personal information which you provide to us
              when you subscribe to our mailing list, enter a competition or
              survey.
            </p>
            <p className="text-start mb-2">
              We may receive some of your personal information from third
              parties or publicly available sources. This includes:
            </p>
            <ul className="text-start mb-2">
              <li>(a) We have your explicit consent;</li>
              <li>(b) Where it is necessary in relation to legal claims;</li>
              <li>(c) Where you have made the personal data public.</li>
            </ul>
            <p className="text-start mb-2">
              {" "}
              5.3 We may also receive Website, Device and Technical Information
              automatically from technologies such as cookies which are
              installed on our website. To find out more about these please see
              our cookie policy;
            </p>

            <h2 className="text-start mb-2">
              6. Details about who personal information may be shared with
            </h2>
            <p className="text-start mb-2">
              6.1 We may need to share your personal information with other
              organisations or people. These organisations include:
            </p>

            <p className="text-start mb-2">
              (a) Other companies in our group: Other companies in our group who
              may act as joint data controllers or as data processors on our
              behalf and who describe the services they provide which require
              them to have access to personal information, e.g., IT services, or
              describe the reasons it may be shared with them, e.g., for
              management reporting.
            </p>

            <ul className="text-start mb-2">
              (b) Third parties who are not part of our group. These may
              include:
              <li>
                (i) Suppliers: such as IT support services, payment providers,
                administration providers, marketing agencies (who are based in
                the United Kingdom)
              </li>
              <li>
                (ii) Government bodies and regulatory bodies: such as HMRC,
                fraud prevention agencies (who are based in the United Kingdom)
              </li>
              <li>
                (iii) Our advisors: such as lawyers, accountants, auditors,
                insurance companies (who are based in the United Kingdom)
              </li>
              <li>(iv) Our bankers (who are based in the United Kingdom)</li>
              <li>
                (v) Credit Reference Agencies (who are based in the United
                Kingdom)
              </li>
              <li>
                (vi) E-mail platforms (who are based in the United Kingdom)
              </li>
            </ul>

            <p className="text-start mb-2">
              (c) any organisations which propose to purchase our business and
              assets in which case we may disclose your personal information to
              the potential purchaser.
            </p>

            <p className="text-start mb-2">
              6.2 Depending on the circumstances, the organisations or people
              who we share your personal information with will be acting as
              either Data Processors or Data Controllers. Where we share your
              personal information with a Data Processor, we will ensure that we
              have in place contracts, which set out the responsibilities and
              obligations of us and them, including in respect of security of
              personal information.
            </p>

            <p className="text-start mb-2">
              6.3 We do not sell or trade any of the personal information which
              you have provided to us.
            </p>

            <h2 className="text-start mb-2">
              7. Details about transfers to countries outside of the EEA
            </h2>
            <p className="text-start mb-2">
              {" "}
              7.1 We do not transfer your personal information outside of the
              EEA.
            </p>
            <p className="text-start mb-2">
              {" "}
              7.2 The safeguards set out in data protection laws for
              transferring personal information outside of the EEA include:
            </p>
            <ul className="text-start mb-2">
              <li>
                {" "}
                (a) where the transfer is to a country or territory which the EU
                Commission has approved as ensuring an adequate level of
                protection;
              </li>
              <li>
                (b) where personal information is transferred to another
                organisation within our group, under an agreement covering this
                situation which is known as “binding corporate rules”;
              </li>
              <li>
                {" "}
                (c) having in place a standard set of clauses which have been
                approved by the EU Commission;
              </li>
              <li>
                (d) compliance with an approved code of conduct by a relevant
                data protection supervisory authority (in the UK, this is the
                Information Commissioner’s Office (ICO);
              </li>
              <li>
                (e) certification with an approved certification mechanism;
              </li>
              <li>
                {" "}
                (f) where the EU Commission has approved specific arrangements
                in respect of certain countries, such as the US Privacy Shield,
                in relation to organisations which have signed up to it in the
                USA.
              </li>
            </ul>

            <h2 className="text-start mb-2">
              8. Details about how long we will hold your personal information
            </h2>
            <p className="text-start mb-2">
              {" "}
              8.1 We will only hold your personal data for as long as is
              necessary. How long is necessary will depend upon the purposes for
              which we collected the personal information (see section 4 above)
              and whether we are under any legal obligation to keep the personal
              information (such as in relation to accounting or auditing records
              or for tax reasons). We may also need to keep personal information
              in case of any legal claims, including in relation to any
              guarantees or warranties which we have provided with the services,
              digital content.
            </p>
            <p className="text-start mb-2">
              {" "}
              8.2 We have set out above the details of our retention periods for
              different types of data. You can find them in in section 2.
            </p>

            <h2 className="text-start mb-2">9. Automated decision making</h2>
            <p className="text-start mb-2">
              {" "}
              9.1 ‘Automated decision making’ is where a decision is
              automatically made without any human involvement. Under data
              protection laws, this includes profiling. ‘Profiling’ is the
              automated processing of personal data to evaluate or analyse
              certain personal aspects of a person (such as their behaviour,
              characteristics, interests and preferences).
            </p>
            <p className="text-start mb-2">
              9.2 Data protection laws place restrictions upon us if we carry
              out automated decision making (including profiling) which produces
              a legal effect or similarly significant effect on you.
            </p>
            <p className="text-start mb-2">
              {" "}
              9.3 We do carry out automated decision making (including
              profiling) which produces a legal effect or similarly significant
              effect on you. If we do decide to do this then we will notify you
              and we will inform you of the legal reason we are able to do this.
              ]
            </p>

            <h2 className="text-start mb-2">
              10. Your rights under data protection law
            </h2>
            <p className="text-start mb-2">
              {" "}
              10.1 Under data protection laws you have certain rights in
              relation to your personal information, as follows:
            </p>
            <ul className="text-start mb-2">
              <li>
                (a) <strong>Right to request access</strong>: (this is often
                called ‘subject access’). This is the right to obtain from us a
                copy of the personal information which we hold about you. We
                must also provide you with certain other information in response
                to these requests to help you understand how your personal
                information is being used.
              </li>
              <li>
                {" "}
                (b)<strong>Right to correction</strong> : this is the right to
                request that any incorrect personal data is corrected and that
                any incomplete personal data is completed.
              </li>
              <li>
                {" "}
                (c) <strong>Right to erasure</strong>: (this is often called the
                “right to be forgotten”). This right only applies in certain
                circumstances. Where it does apply, you have the right to
                request us to erase all of your personal information.
              </li>
              <li>
                (d)<strong>Right to restrict processing</strong> : this right
                only applies in certain circumstances. Where it does apply, you
                have the right to request us to restrict the processing of your
                personal information.
              </li>
              <li>
                {" "}
                (e) <strong>Right to data portability</strong>: this right
                allows you to request us to transfer your personal information
                to someone else.
              </li>
              <li>
                {" "}
                (f)<strong>Right to data portability</strong>Right to data
                portability: you have the right to object to us processing your
                personal information for direct marketing purposes.You also have
                the right to object to us processing personal information where
                our legal reason for doing so is the Legitimate Interests Reason
                (see section 4 above) and there is something about your
                particular situation which means that you want to object to us
                processing your personal information. In certain circumstances
                you have the right to object to processing where such processing
                consists of profiling (including profiling for direct
                marketing).
              </li>
            </ul>
            <p className="text-start mb-2">
              {" "}
              10.2 In addition to the rights set out in section 10.1, where we
              rely on consent as the legal reason for using your personal
              information, you have the right to withdraw your consent. Further
              details about this are set out in section 4.5.
            </p>
            <p className="text-start mb-2">
              {" "}
              10.3 If you want to exercise any of the above rights in relation
              to your personal information, please contact us using the details
              set out at the beginning of this notice. If you do make a request
              then please note:
            </p>
            <ul className="text-start mb-2">
              <li>
                (a) we may need certain information from you so that we can
                verify your identity;
              </li>
              <li>
                (b) we do not charge a fee for exercising your rights unless
                your request is unfounded or excessive; and
              </li>
              <li>
                {" "}
                (c) if your request is unfounded or excessive then we may refuse
                to deal with your request.
              </li>
            </ul>
            <h2 className="text-start mb-2">11. Marketing</h2>
            <ul className="text-start mb-2">
              <li>
                11.1 You may receive marketing from us about similar goods and
                services, where either you have consented to this, or we have
                another legal reason by which we can contact you for marketing
                purposes.
              </li>
              <li>
                11.2 However, we will give you the opportunity to manage how or
                if we market to you. In any e- mail which we send to you, we
                provide a link to either unsubscribe or opt-out, or to change
                your marketing preferences. If you have an account with us, you
                can login to your account and manage your preferences there too.
                To change your marketing preferences, and/or to request that we
                stop processing your personal information for marketing purposes
                , you can always contact us on the details set out at the
                beginning of this notice.
              </li>
              <li>
                {" "}
                11.3 If you do request that we stop marketing to you, this will
                not prevent us from sending communications to you which are not
                to do with marketing (for example in relation to services
                digital content which you have purchased from us).
              </li>
              <li>
                {" "}
                11.4 We do not ass your personal information on to any third
                parties for marketing purposes.
              </li>
            </ul>

            <h2 className="text-start mb-2">12. Complaints</h2>
            <p className="text-start mb-2">
              If you are unhappy about the way that we have handled or used your
              personal information, you have the right to complain to the UK
              supervisory authority for data protection, which is the
              Information Commissioner’s Office (ICO). Please do contact us in
              the first instance if you wish to raise any queries or make a
              complaint in respect of our handling or use of your personal
              information, so that we have the opportunity to discuss this with
              you and to take steps to resolve the position. You can contact us
              using the details set out at the beginning of this privacy notice.
            </p>

            <h2 className="text-start mb-2">13. Third Party Websites</h2>
            <p className="text-start mb-2">
              Our website may contain links to third-party websites. If you
              click and follow those links then these will take you to the
              third-party website. Those third-party websites may collect
              personal information from you, and you will need to check their
              privacy notices to understand how your personal information is
              collected.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
