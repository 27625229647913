import React, { useState, useEffect } from "react";
import { fetchCategoryApi } from "../APIS/MainApis";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { addToWishlistApi } from "../APIS/MainApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StarRatings from "react-star-ratings";
import "../Style/AllCourses.css";
import { useSelector } from "react-redux";

function AllCourses() {
  const token = localStorage.getItem("token");
  const user = useSelector((state) => state?.user?.user);

  const params = useParams();
  const categoryId = params?.categoryId;

  const navigate = useNavigate();
  const handleNavigate = (courseId) => {
    navigate(`/singlecategory/${courseId}`);
    window.scrollTo(0, 0);
  };

  const [categories, setCategories] = useState([]);
  const [selectedCategoryCourses, setSelectedCategoryCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all courses");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);
  const [selectedRating, setSelectedRating] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);

  useEffect(() => {
    fetchCategoryApi()
      .then((response) => {
        if (response && response?.data && response?.data?.category_data) {
          setCategories(response?.data?.category_data);
          const selectedCategory = response?.data?.category_data?.find(
            (category) => category?.id === parseInt(categoryId, 10)
          );

          if (categoryId === undefined) {
            const allCourses = response?.data?.category_data.reduce(
              (all, category) => all.concat(category.courses),
              []
            );
            setSelectedCategoryCourses(allCourses);
            setSelectedCategory("all courses");
          } else if (selectedCategory) {
            setSelectedCategoryCourses(selectedCategory?.courses);
            setSelectedCategory(selectedCategory?.name);
          }
        } else {
          console.error("Invalid API response:", response);
        }
      })
      .catch((error) => {
        console.error("API call failed:", error);
      });
  }, [categoryId]);

  const addToWishlist = async (id) => {
    if (user === null) {
      toast.error("Login to continue");
      return;
    }
    const wishlist = {
      course_id: id,
    };
    const response = await addToWishlistApi(wishlist, token);
    toast.dismiss();
    if (response) {
      toast.success(response?.data?.responses[0]?.message);
    } else {
      toast.error("Failed to add product to cart");
    }
  };

  const handleCategoryChange = (event) => {
    const newSelectedCategory = event.target.value;

    if (newSelectedCategory === "all courses") {
      setSelectedCategory("all courses");
      const allCourses = categories.reduce(
        (all, category) => all.concat(category.courses),
        []
      );
      setSelectedCategoryCourses(allCourses);
      navigate("/allcourses");
    } else {
      setSelectedCategory(newSelectedCategory);

      const coursesForSelectedCategory = categories.find(
        (category) => category.name === newSelectedCategory
      )?.courses;

      if (coursesForSelectedCategory) {
        setSelectedCategoryCourses(coursesForSelectedCategory);
      } else {
        setSelectedCategoryCourses([]);
      }
      const selectedCategory = categories.find(
        (category) => category.name === newSelectedCategory
      );

      if (selectedCategory) {
        const newCategoryId = selectedCategory.id;
        navigate(`/allcourses/${newCategoryId}`);
      }
    }

    setCurrentPage(1);
    setSelectedRating(null);
    setSelectedPrice(null);
  };

  const filterCoursesByRating = (courses, rating) => {
    if (!rating) {
      return courses;
    }
    return courses.filter((course) => course.avgRating >= rating);
  };

  const filterCoursesByPrice = (courses, price) => {
    if (!price) {
      return courses;
    }
    return courses.filter((course) => course.discount_price <= price);
  };

  const filteredCourses = filterCoursesByPrice(
    filterCoursesByRating(selectedCategoryCourses, selectedRating),
    selectedPrice
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCourses?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const renderedCourses = currentItems?.map((course) => (
    <div className="col gy-3" key={course.id}>
      <div className="card allcourses_card">
        <img
          src={course.image}
          className="allcourses_image"
          alt={course.name}
          onClick={() => handleNavigate(course.id)}
        />
        <div className="card-body" onClick={() => handleNavigate(course.id)}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="allcourses_level">{course?.level}</span>
            <span className="allcourses_heart">
              <h5 className="allcourses_price">
                &#163;{course?.discount_price}
              </h5>
            </span>
          </div>
          <p className="truncated-title text-start py-1">{course?.name}</p>
          <p className="allcourses_whom text-start py-1">
            By: {course?.author}
          </p>
          <div className="text-start category_span">
            <div className="d-flex align-items-center">
              <span className="categorycomponent_avgrating">
                {parseFloat(course?.avgRating)}
              </span>
              <span className="allcourses_comments">({course?.totalUser})</span>
            </div>
            <div>
              <StarRatings
                rating={parseFloat(course?.avgRating)}
                starRatedColor="#f69c08"
                starEmptyColor="#E0E0E0"
                numberOfStars={5}
                starDimension="13px"
                starSpacing="2px"
                name="rating"
              />
            </div>
          </div>
        </div>
        <div className="card-footer allcourses_cardfooter d-flex justify-content-between align-items-center">
          <div>
            <button
              className="allcourses_enroll"
              onClick={() => handleNavigate(course.id)}
            >
              View Details
            </button>
          </div>
          <div>
            <button
              className="allcourses_enroll"
              onClick={() => addToWishlist(course.id)}
            >
              Add to Wishlist
            </button>
          </div>
        </div>
      </div>
    </div>
  ));

  const pageCount = Math.ceil(filteredCourses?.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <>
      <Navbar selectedCategory={selectedCategory} />
      <div className="container py-5 allcourses-container">
        <div className="row">
          <div className="col-lg-3">
            <div className="allcourses_sidebar">
              <h3>Filters</h3>
              <div className="allcourses_filter">
                <div className="text-start">
                  <h5>Category</h5>
                </div>
                <div className="d-flex flex-column text-start">
                  <div className="allcourses_checkboxdata d-flex align-items-center">
                    <label className="allcourses_checkbox_label">
                      <input
                        type="radio"
                        name="category"
                        value="all courses"
                        checked={selectedCategory === "all courses"}
                        onChange={handleCategoryChange}
                      />{" "}
                      All Courses
                    </label>
                  </div>

                  {categories?.map((category, i) => (
                    <div
                      key={i}
                      className="allcourses_checkboxdata d-flex align-items-center"
                    >
                      <label className="allcourses_checkbox_label">
                        <input
                          type="radio"
                          name="category"
                          value={category.name}
                          checked={selectedCategory === category.name}
                          onChange={handleCategoryChange}
                        />{" "}
                        {category.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="allcourses_filter">
                <div className="text-start">
                  <h5>Rating</h5>
                </div>
                <div className="d-flex flex-column text-start">
                  <label className="allcourses_checkbox_label">
                    <input
                      type="radio"
                      name="rating"
                      value="4"
                      checked={selectedRating === 4}
                      onChange={() => setSelectedRating(4)}
                    />{" "}
                    4 and above
                  </label>

                  <label className="allcourses_checkbox_label">
                    <input
                      type="radio"
                      name="rating"
                      value="3"
                      checked={selectedRating === 3}
                      onChange={() => setSelectedRating(3)}
                    />{" "}
                    3 and above
                  </label>

                  <label className="allcourses_checkbox_label">
                    <input
                      type="radio"
                      name="rating"
                      value="2"
                      checked={selectedRating === 2}
                      onChange={() => setSelectedRating(2)}
                    />{" "}
                    2 and above
                  </label>

                  <label className="allcourses_checkbox_label">
                    <input
                      type="radio"
                      name="rating"
                      value="1"
                      checked={selectedRating === 1}
                      onChange={() => setSelectedRating(1)}
                    />{" "}
                    1 and above
                  </label>

                  <label className="allcourses_checkbox_label">
                    <input
                      type="radio"
                      name="rating"
                      value="0"
                      checked={selectedRating === 0}
                      onChange={() => setSelectedRating(0)}
                    />{" "}
                    0 and above
                  </label>
                </div>
              </div>
              <div className="allcourses_filter">
                <div className="text-start">
                  <h5>Price</h5>
                </div>
                <div className="d-flex flex-column text-start">
                  <label className="allcourses_checkbox_label">
                    <input
                      type="radio"
                      name="price"
                      value="50"
                      checked={selectedPrice === 50}
                      onChange={() => setSelectedPrice(50)}
                    />{" "}
                    Less than &#163;50
                  </label>

                  <label className="allcourses_checkbox_label">
                    <input
                      type="radio"
                      name="price"
                      value="100"
                      checked={selectedPrice === 100}
                      onChange={() => setSelectedPrice(100)}
                    />{" "}
                    Less than &#163;100
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            {filteredCourses.length === 0 ? (
              <div className="d-flex align-items-center justify-content-center">
                <h2>No courses to display for the selected filters.</h2>
              </div>
            ) : (
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-3">
                {renderedCourses}
              </div>
            )}

            {pageCount > 1 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={0}
                pageRangeDisplayed={0}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default AllCourses;
