import React, { useEffect, useState } from "react";
import "../Style/PurchaseHistorySection.css";
import { PurchasedCoursesApi } from "../APIS/MainApis";
import InvoiceDetailsModal from "./InvoiceDetailsModal";

function PurchaseHistorySection() {
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  console.log(purchasedCourses, "p");

  const token = localStorage.getItem("token");

  useEffect(() => {
    async function fetchPurchasedCourses() {
      PurchasedCoursesApi(token, (data) => {
        const response = data;

        if (response && response?.data && response?.data?.purchasedCourses) {
          const purchasedCoursesMap = response?.data?.purchasedCourses;
          const uniqueOrders = [];

          for (const orderId in purchasedCoursesMap) {
            const userCourses = purchasedCoursesMap[orderId]?.purchasedCourses;
            const firstCourse = userCourses[0];

            uniqueOrders.push({
              orderId,
              date: firstCourse?.created_at,
              price: firstCourse?.totalPrice,
              paymentType: firstCourse?.paymentType,
              transactionid: firstCourse?.transactionId,
              courses: userCourses,
            });
          }

          setPurchasedCourses(uniqueOrders);
        }
      });
    }
    fetchPurchasedCourses();
  }, [token]);

  const handleViewInvoice = (order) => {
    setSelectedOrder(order);
  };

  const closeInvoiceModal = () => {
    setSelectedOrder(null);
  };

  return (
    <div className="container p-0">
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="purchasehistorysection_table">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Date</th>
                  <th>Price</th>
                  <th>Payment Type</th>
                  <th>View Details</th>
                </tr>
              </thead>
              <tbody>
                {purchasedCourses?.length === 0 ? (
                  <>
                    <tr>
                      <td colSpan="5">
                        <p className="d-flex align-items-center justify-content-center">No purchase History</p>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    {purchasedCourses?.map((order, orderIndex) => (
                      <tr key={orderIndex}>
                        <td>{order.orderId}</td>
                        <td>{order.date}</td>
                        <td>${order.price}</td>
                        <td>{order.paymentType}</td>
                        <td>
                          <button
                            className="purchasehistorysection_button"
                            onClick={() => handleViewInvoice(order)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedOrder && (
        <InvoiceDetailsModal
          order={selectedOrder}
          onClose={closeInvoiceModal}
        />
      )}
    </div>
  );
}

export default PurchaseHistorySection;
