import React from "react";
import "../Style/WishListSection.css";
import { deleteWishlistApi } from "../APIS/MainApis";
import { fetchWishlistApi } from "../APIS/MainApis";
import { AddToCartCartApi } from "../APIS/MainApis";
import { fetchCartApi } from "../APIS/MainApis";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { add } from "../store/cartSlice";
import "react-toastify/dist/ReactToastify.css";
import StarRatings from "react-star-ratings";

function WishlistSection() {
  const [wishlist, setWishlist] = useState([]);
  console.log(wishlist, "w");
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const handleNavigate = (courseId) => {
    navigate(`/singlecategory/${courseId}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchUserWishlist = async () => {
      fetchWishlistApi(token, (data) => {
        console.log(data);
        const response = data;
        if (response && response?.data && response?.data?.wishlistItems) {
          setWishlist(response?.data?.wishlistItems);
        }
      });
    };
    fetchUserWishlist();
  }, [token]);

  const deletefromWishlist = async (id) => {
    const wishlistdata = {
      courseId: id,
    };
    try {
      const response = await deleteWishlistApi(wishlistdata, token);
      toast.dismiss();
      if (response) {
        toast.success(response?.data?.message);
        setWishlist((prevWishlist) =>
          prevWishlist.filter((item) => item.courseId !== id)
        );
      } else {
        toast.error("Failed to delete product in wishlist");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddToCart = async (courseId) => {
    try {
      const response = await AddToCartCartApi(token, [courseId]);

      if (
        response?.data?.responses[0]?.message ===
        "Course is already purchased by the user."
      ) {
        toast.dismiss();
        const messageFromApi = response?.data?.responses[0]?.message;
        toast.success(messageFromApi);
        return;
      }

      const messageFromApi = response?.data?.responses[0]?.message;
      toast.dismiss();

      if (response) {
        toast.success(messageFromApi);
      } else {
        toast.error("Failed to add product to cart");
      }

      fetchCartApi(token, (data) => {
        const response2 = data;
        for (const item of response2?.data?.cartItems) {
          dispatch(add(item));
        }
      });
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  return (
    <>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 mb-5">
        {wishlist?.length === 0 ? (
          <>
            <div style={{ width: "100%" }}>Your Wishlist is empty</div>
          </>
        ) : (
          <>
            {wishlist.map((item) => (
              <div key={item?.courseId} className="col gy-3">
                <div className="card wishlistsection_card">
                  <img
                    src={item?.courseImage}
                    className="wishlistsection_image"
                    alt=""
                    onClick={() => handleNavigate(item?.courseId)}
                  />
                  <div
                    className="card-body"
                    onClick={() => handleNavigate(item?.courseId)}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="wishlistsection_level">
                        {item.courseLevel}
                      </span>

                      <h5 className="wishlistsection_price">
                        ${item?.coursePrice}
                      </h5>
                    </div>
                    <p className="truncated-title text-start py-2">
                      {item.courseName}
                    </p>
                    <div className="d-flex mb-2 wishlistsection_whom">
                      By: {item?.authorName}
                    </div>

                    <div className="text-start category_span">
                      <div className="d-flex align-items-center">
                        <span className="wishlistsection_avgrating">
                          {item?.totalRating}
                        </span>
                        <span className="wishlistsection_comments">
                          ({item?.totalUsers})
                        </span>
                      </div>
                      <div>
                        <StarRatings
                          rating={parseInt(item?.totalRating)}
                          starRatedColor="#f69c08"
                          starEmptyColor="#E0E0E0"
                          numberOfStars={5}
                          starDimension="13px"
                          starSpacing="2px"
                          name="rating"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between align-items-center">
                    <div>
                      <button
                        className="enroll"
                        onClick={() => deletefromWishlist(item?.courseId)}
                      >
                        Remove
                      </button>
                    </div>
                    <div>
                      <button
                        className="enroll"
                        onClick={() => handleAddToCart(item?.courseId)}
                      >
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
        <ToastContainer />
      </div>
    </>
  );
}

export default WishlistSection;
