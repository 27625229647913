import React from 'react'
import Login from './Auth/Login';
import Signup from './Auth/Signup';
import Home from './Home/Home';
import SingleCategory from './Components/SingleCategory'
import AboutUs , {aboutInfoLoader} from './Components/AboutUs'
import ContentsMenu from './Components/ContentsMenu';
import ContactUs from './Components/ContactUs';
import Faq from './Components/Faq';
import Cart from "./Components/Cart"; 
import MyAccount from "./Components/MyAccount"
import AllCourses from './Components/AllCourses';
import Blog ,{blogInfoLoader} from './Components/Blog';
import PrivacyPolicy from './Components/PrivacyPolicy';
import  DataPolicy from './Components/DataPolicy';
import BlogsDescriptions from './Components/BlogsDescriptions';
import CookiesPolicy from './Components/CookiesPolicy'; 
import Payment from './Components/Payment'; 
import SearchCategory from './Components/SearchCategory'; 
import {createBrowserRouter,RouterProvider} from "react-router-dom"; 
import Meeting from './Components/Meeting';



const router = createBrowserRouter([
  { 
    path: "/",
    element: <Home />, 
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />, 
  },
  {
    path: "/singlecategory/:courseId",
    element: <SingleCategory />,
  },
  {
    loader:aboutInfoLoader,
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/contentmenu",
    element: <ContentsMenu />,
  },
  { 
    path: "/contactus",
    element: <ContactUs />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/cart",
    element: <Cart />,
  },
  {
    path: "/myaccount",
    element: <MyAccount />,
  },
  {
    path:"/allcourses/:categoryId",
    element: <AllCourses />,
  },
  {
    path:"/allcourses",
    element: <AllCourses />,
  },
  {
    path:"/blog",
    element: <Blog />,
  },
  {
    path:"/privacypolicy",
    element: <PrivacyPolicy />,
  }, 
  {
    path:"/datapolicy",
    element: < DataPolicy />,
  },
  {
    path: "/blog/:blogId",
    element: <BlogsDescriptions />,
  },
  {
    path: "/cookiespolicy",
    element: <CookiesPolicy />,
  },
  {
    path: "/payment",
    element: <Payment />,
  },
  {
    path: "/searchcategory",
    element: <SearchCategory />,
  },
  { 
    path: "/meeting",
    element: <Meeting />, 
  },
]);

function Route() { 
  return (
     <>
     <RouterProvider router={router} />
     </>
  )
}

export default Route