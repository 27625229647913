import axios from "axios";
import { baseURL } from "../baseURL";
import { ToastContainer, toast } from "react-toastify";

export async function fetchuserApi(token) {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(`${baseURL}auth/fetch-user`, { headers });
    return response;
  } catch (error) {
    console.log("Error in fetchuserApi:", error);
    return null;
  }
}

export async function courseprogressApi(userdata) {
  try {
    const response = await axios.post(
      `${baseURL}auth/user-course-progress`,
      userdata
    );
    return response;
  } catch (error) {
    console.log("Error in courseprogressApi:", error);
    return null;
  }
}

export async function LessonprogressApi(userdata) {
  try {
    const response = await axios.post(
      `${baseURL}auth/update-lesson-progress`,
      userdata
    );
    return response;
  } catch (error) {
    console.log("Error in LessonprogressApi:", error);
    return null;
  }
}

export async function searchCoursesApi(searchQuery) {
  try {
    const response = await axios.get(
      `${baseURL}auth/search?keyword=${searchQuery}`
    );
    return response;
  } catch (error) {
    console.log("Error in searchCoursesApi:", error);
    return null;
  }
}

export async function PurchasedCoursesApi(token, callback) {
  try {
    axios
      .post(
        `${baseURL}cart/purchased-courses`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        callback(res);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log("Error in PurchasedCoursesApi:", error);
    return null;
  }
}

export async function PaymentApi(paymentdata, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.post(
      `${baseURL}auth/purchased-courses`,
      paymentdata,
      { headers }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error in Payment Api:", error);
    return null;
  }
}

export async function fetchWishlistApi(token, callback) {
  try {
    axios
      .post(
        `${baseURL}cart/wishlist-items`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        callback(res);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log("Error in delete wishlist:", error);
    return null;
  }
}

export async function deleteWishlistApi(wishlistcourse, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.post(
      `${baseURL}cart/delete-wishlist-items`,
      wishlistcourse,
      { headers }
    );
    return response;
  } catch (error) {
    console.log("Error in delete wishlist:", error);
    return null;
  }
}

export async function addToWishlistApi(wishlistcourse, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.post(
      `${baseURL}cart/add-to-wishlist`,
      wishlistcourse,
      { headers }
    );
    return response;
  } catch (error) {
    console.log("Error in add to wishlist:", error);
    return null;
  }
}

export async function fetchAdminContactDetails() {
  try {
    const response = await axios.get(`${baseURL}auth/contacts`);
    return response;
  } catch (error) {
    console.log("Error fetching admin contact details:", error);
    return null;
  }
}

export async function RefundPolicyApi() {
  try {
    const response = await axios.get(`${baseURL}auth/refund-policy`);
    return response;
  } catch (error) {
    console.log("Error in refund policy:", error);
    return null;
  }
}

export async function TermsAndConditionApi() {
  try {
    const response = await axios.get(`${baseURL}auth/terms-and-conditions`);
    return response;
  } catch (error) {
    console.log("Error in terms and condition:", error);
    return null;
  }
}

export async function fetchBlogPostApi(blogid) {
  try {
    const response = await axios.post(`${baseURL}auth/blog-post/${blogid}`);
    return response;
  } catch (error) {
    console.log("Error Deleting cart items:", error);
    return null;
  }
}

export async function DeleteCartApi(deletedata, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.post(
      `${baseURL}cart/delete-cart-items`,
      deletedata,
      { headers }
    );
    return response;
  } catch (error) {
    console.log("Error Deleting cart items:", error);
    return null;
  }
}

export async function fetchCartApi(token, callback) {
  try {
    axios
      .post(
        `${baseURL}cart/cart-items`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        callback(res);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log("Error fetching cart data:", error);
    return null;
  }
}

export async function SubmitReviewApi(reviewdata, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.post(`${baseURL}auth/ratings`, reviewdata, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error fetching Submit Review:", error);
    return null;
  }
}

export async function PrivacyPolicyApi() {
  try {
    const response = await axios.get(`${baseURL}auth/privacy-policy`);
    return response;
  } catch (error) {
    console.log("Error fetching About Us:", error);
    return null;
  }
}

export async function BlogApi() {
  try {
    const response = await axios.post(`${baseURL}auth/blog-category`);
    return response;
  } catch (error) {
    console.log("Error fetching About Us:", error);
    return null;
  }
}

export async function AboutUsApi() {
  try {
    const response = await axios.get(`${baseURL}auth/about-us`);
    return response;
  } catch (error) {
    console.log("Error fetching About Us:", error);
    return null;
  }
}

export async function fetchTopCoursesApi() {
  try {
    const response = await axios.get(`${baseURL}auth/top-courses`);
    return response;
  } catch (error) {
    console.log("Error fetching New Courses:", error);
    return null;
  }
}

export async function fetchNewCoursesApi() {
  try {
    const response = await axios.get(`${baseURL}auth/new-courses`);
    return response;
  } catch (error) {
    console.log("Error fetching New Courses:", error);
    return null;
  }
}

export async function ContactUsApi(contactusdata) {
  try {
    console.log(contactusdata);
    const response = await axios.post(
      `${baseURL}auth/home/contact/store`,
      contactusdata
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error fetching user profile:", error);
    return null;
  }
}

export async function ProfileUpdateApi(userupdateddata, token, setUpdatePage) {
  setUpdatePage(true);
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(
      `${baseURL}auth/info-update`,
      userupdateddata,
      { headers }
    );
    setUpdatePage(false);
    toast.success(response?.data.message);
    return response;
  } catch (error) {
    console.log("Error fetching user profile:", error);
    return null;
  }
}
export async function AddToCartCartApi(token, productid) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const requestData = {
      course_ids: productid,
    };
    const response = await axios.post(
      `${baseURL}cart/add-to-cart`,
      requestData,
      { headers }
    );
    console.log(response, "add to cart in mainapi");
    return response;
  } catch (error) {
    console.log("Error Cart:", error);
    return null;
  }
}

export async function fetchFaqApi() {
  try {
    const response = await axios.get(`${baseURL}auth/faqs`);
    return response;
  } catch (error) {
    console.log("Error Faq:", error);
    return null;
  }
}

export async function newPasswordApi(token, newPassword, confirmPassword) {
  try {
    const response = await axios.post(
      `${baseURL}auth/reset-password?token=${token}&newPassword=${newPassword}&confirmPassword=${confirmPassword}`
    );
    return response;
  } catch (error) {
    console.log("Error resetting password:", error);
    return null;
  }
}

export async function validateOTPApi(userentered_otp) {
  try {
    const response = await axios.post(
      `${baseURL}auth/otp-verification?otp=${userentered_otp}`
    );
    return response;
  } catch (error) {
    console.log("Error fetching and validating OTP:", error);
    return false;
  }
}

export async function fetchStudentMessageApi() {
  try {
    const response = await axios.get(`${baseURL}auth/testimonials`);
    return response.data;
  } catch (error) {
    console.log("Error fetching Student Messages:", error);
    return null;
  }
}

export async function fetchCategoryApi() {
  try {
    const response = await axios.post(`${baseURL}auth/category`);
    console.log(response,"mainapi")
    return response.data;
  } catch (error) {
    console.log("Error fetching course details:", error);
    return null;
  }
}

export async function fetchSingleCategoryApi(courseId) {
  try {
    const response = await axios.post(
      `${baseURL}auth/course-list/curriculum/${courseId}`
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching course details:", error);
    return null;
  }
}

export async function fetchLoginApi(userData) {
  try {
    const response = await axios.post(`${baseURL}auth/login`, userData);
    return response;
  } catch (error) {
    console.log("Error fetching login details:", error);
    return null;
  }
}
