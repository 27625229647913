import React from "react";
import "../Style/AboutUs.css";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { AboutUsApi } from "../APIS/MainApis";
// import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";

function AboutUs() {
  // const [aboutUs, setAboutUs] = useState([]);

  const aboutUs = useLoaderData();
  console.log(aboutUs);
  // useEffect(() => {
  //   async function fetchaboutus() {
  //     const response = await AboutUsApi();
  //     if (response.data && response.data.data.about_us_data[0]) {
  //       setAboutUs(response.data.data.about_us_data[0]);
  //     }
  //   }
  //   fetchaboutus();
  // }, []);

  return (
    <>
      <Navbar />
      <div className="container-fluid p-0 aboutus_container">
        <div className="row">
          <div className="col-lg-12 aboutus_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 aboutus_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">About Us</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container p-4">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div
              className="text-start"
              dangerouslySetInnerHTML={{ __html: aboutUs?.about_desc }}
            ></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;

export const aboutInfoLoader = async () => {
  const response = await AboutUsApi();
  if (response && response?.data && response?.data?.data?.about_us_data[0]) {
    return response?.data?.data?.about_us_data[0];
  }
}