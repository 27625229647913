import React from "react";
import "../Style/Premium.css";
function Premium() {
  return (
    <div className="premium-container">
      <div className="container p-5">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6">
            <img src="studying.png" width="260px" className="premium_studyimage" alt="" />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
            <div>
              <div className="premium_heading text-white fw-bold text-lg-start mt-lg-0 mt-5">
                Elevate Your Learning with<br></br> Safety and Training Academy
              </div>
              <div className="mt-5 mb-5 d-flex">
                <div className="d-flex justify-content-center align-items-center">
                  <img src="heart.png" alt="" />
                </div>
                <div className="px-3">
                  <h5 className="text-start text-white fw-bolder">
                    User-Friendly and Accessible
                  </h5>
                  <p className="text-white text-start">
                    Safety and Training Academy ensures a comfortable learning
                    journey.
                  </p>
                </div>
              </div>

              <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center">
                  <img src="puzzle.png" alt="" />
                </div>
                <div className="px-3">
                  <h5 className="text-start text-white fw-bolder">
                    Interactive Learning
                  </h5>
                  <p className="text-white text-start">
                    Engage with courses through interactive puzzles and
                    activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Premium;
