import React from "react";
import "../Style/CategoryDescription.css";
import { AiOutlineBug } from "react-icons/ai";
import { ImEarth } from "react-icons/im";
import StarRatings from "react-star-ratings";

function CategoryDescription({ about }) {
  const averageRating = !isNaN(about?.avgRating)
    ? parseFloat(about?.avgRating)
    : 0;

  return (
    <>
      <div className="cd_container">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="text-start">
                <p className="cd_heading fw-bold">{about?.category_name}</p>
              </div>

              <div className="text-start">
                <h1 className="cd_heading2 fw-bold">{about?.title}</h1>
              </div>

              <div className="text-start">
                <p className="cd_about">{about?.course_Shortdescription}</p>
              </div>

              <div className="text-start mb-2">
                <div className="d-flex align-items-center cd_ratings">
                  <p className="cd_rating_number fw-bold">
                    {averageRating.toFixed(1)}{" "}
                    <span className="cd_totaluser">({about?.totalUsers})</span>
                  </p>
                </div>
                <div>
                  <StarRatings
                    rating={averageRating}
                    starRatedColor="#f69c08"
                    starEmptyColor="#E0E0E0"
                    numberOfStars={5}
                    starDimension="13px"
                    starSpacing="2px"
                    name="rating"
                  />
                </div>
              </div>

              <div className="text-start mb-2">
                <span className="text-white cd_created_by">Created by</span>
                <span className="cd_creator_name">{about?.course_author}</span>
              </div>

              <div className="text-start text-white cd_last">
                <span className="cd_lastupdated">
                  <AiOutlineBug />
                  &nbsp;Last Updated At {about?.last_updated}
                </span>
                <span className="cd_language">
                  <ImEarth />
                  &nbsp;English
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryDescription;
