import React from "react";
import "../Style/Sidebar.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { searchCoursesApi } from "../APIS/MainApis";

function Sidebar({ isOpen, closeSidebar, categoryDropdown, selectedCategory }) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleCategoryClick = (categoryId) => {
    navigate(`/allcourses/${categoryId}`);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim() === "") {
      return;
    }

    try {
      const response = await searchCoursesApi(searchQuery);
      navigate("/searchcategory", {
        state: { searchResults: response.data.courses },
      });
    } catch (error) {
      console.log("Error searching courses", error);
    }
  };

  const AboutUsNavigate = () => {
    navigate("/aboutus");
    window.scrollTo(0, 0);
  };
  const ContactUsNavigate = () => {
    navigate("/contactus");
    window.scrollTo(0, 0);
  };
  const BlogNavigate = () => {
    navigate("/blog");
    window.scrollTo(0, 0);
  };
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="text-start p-3">
        <ul className="navbar-nav text-center">
          <li onClick={AboutUsNavigate} className="nav-item">
            <span className="nav-link navbar_left_link text-white">
              About Us
            </span>
          </li>

          <li onClick={ContactUsNavigate} className="nav-item">
            <span className="nav-link navbar_left_link text-white">
              Contact Us
            </span>
          </li>

          <li onClick={BlogNavigate} className="nav-item ">
            <span href="" className="nav-link navbar_left_link text-white">
              Blog
            </span>
          </li>

          <li className="nav-item "  onClick={() => navigate("/allcourses")}>
            <span href="" className="nav-link navbar_left_link text-white">
              All Courses
            </span>
          </li>

          {/* <li className="nav-item dropdown">
            <span
              className="nav-link dropdown-toggle text-white"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Category
            </span>
            <div
              className="dropdown-menu sidebarcategory_dropdown"
              aria-labelledby="navbarDropdown"
            >
              {categoryDropdown.map((category, i) => (
                <span
                  key={i}
                  className={`dropdown-item ${
                    selectedCategory === category.name ? "active" : ""
                  }`}
                  onClick={() => handleCategoryClick(category.id)}
                >
                  {category.name}
                </span>
              ))}
            </div>
          </li> */}
        </ul>

        <form className="d-flex nav-search w-100" onSubmit={handleSearch}>
          <input
            type="text"
            className="form-control text-center"
            placeholder="Search Courses"
            aria-label="Search"
            aria-describedby="search-icon"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </form>
      </div>
    </div>
  );
}

export default Sidebar;
