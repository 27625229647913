import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import "../Style/PasswordResetForm.css";
import Otp from "./Otp";

const PasswordResetForm = ({ openForgotModal, setOpenForgotModal }) => {
  const [email, setEmail] = useState("");
  const [resetValidationErrors, setResetValidationErrors] = useState({});
  const [resetSuccess, setResetSuccess] = useState(false);
  const [openOtpModal, setOtpModal] = useState(false);

  const resetForm = () => {
    setEmail("");
    setResetValidationErrors({});
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (resetValidationErrors.email) {
      setResetValidationErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!email) {
      errors.email = "Email cannot be empty";
    }
    setResetValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const userData = {
        username: email,
      };
      try {
        const response = await axios.post(
          "https://admin.tsata.co.uk/api/auth/forgot-password",
          userData
        );
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "Password Reset Request Sent",
            text: response.data.message,
          });
          resetForm();
          setResetSuccess(true);
          setOtpModal(true);
          setOpenForgotModal(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Password Reset Request Failed",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.error("Password reset error:", error);
        Swal.fire({
          icon: "error",
          title: "Password Reset Request Failed",
          text: "An error occurred during the password reset request. Please try again later.",
        });
      }
    }
  };

  return (
    <>
      {resetSuccess && (
        <Otp openOtpModal={openOtpModal} setOtpModal={setOtpModal} />
      )}
      <Modal
        show={openForgotModal}
        onHide={() => setOpenForgotModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="password_reset_title">
            Password Reset
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label className="password_reset_label">Email</Form.Label>
              <Form.Control
                style={{
                  border: resetValidationErrors.email ? "1px solid red" : "",
                  color: resetValidationErrors.email ? "red" : "",
                }}
                className="password_reset-formcontrol"
                placeholder="Enter your email"
                type="email"
                value={email}
                onChange={handleEmailChange}
              />
              {resetValidationErrors.email && (
                <div className="passsword_reset_error">
                  {resetValidationErrors.email}
                </div>
              )}
            </Form.Group>
            <Button
              className="password_reset_button"
              variant="primary"
              type="submit"
            >
              Reset Password
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PasswordResetForm;
