import React, { useEffect } from "react";
// import { ZoomMtg } from "@zoomus/websdk";

function Meeting({ payload }) {
//   useEffect(() => {
//     const { ZoomMtg } = import("@zoomus/websdk");
//     ZoomMtg.setZoomJSLib("https://source.zoom.us/lib", "/av");
//     ZoomMtg.preLoadWasm();
//     ZoomMtg.prepareWebSDK();

//     ZoomMtg.generateSDKsignature({
//       meetingNumber: payload.meetingNumber,
//       role: payload.role,
//       sdkKey: payload.sdkKey,
//       sdkSecret: payload.sdkSecret,
//       success: function (signature) {
//         ZoomMtg.init({
//           leaveUrl: payload.leaveUrl,
//           success: function (data) {
//             ZoomMtg.join({
//               meetingNumber: payload.meetingNumber,
//               signature: signature.result,
//               userName: payload.userName,
//               userEmail: payload.userEmail,
//               password:payload.passWord,
//               tk:'',
//               success:function(){
//                 console.log("Joined")
//               },
//               error:function(error)
//               {
//                  console.log(error)
//               }
//             });
//           },
//           error: function (error) {
//             console.log(error);
//           },
//         });
//       },
//       error: function (error) {
//         console.log(error);
//       },
//     });
//   }, []);
  return <div>hello</div>;
}

export default Meeting;
