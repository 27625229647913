import React from "react";
import { useState } from "react";
import "../Style/MyAccount.css";
import Navbar from "./Navbar";
import MyAccountNavbar from "./MyAccountNavbar";
import ProfileSection from "../Components/ProfileSection";
import MyCoursesSection from "../Components/MyCoursesSection";
import PurchaseHistorySection from "../Components/PurchaseHistorySection";
import WishlistSection from "../Components/WishlistSection";
import MyCertificates from "./MyCertificates";
import Footer from "../Components/Footer";


function Profile() {
  
  const [activeTab, setActiveTab] = useState("myprofile");

  return (
    <>
      <Navbar />
      <div className="container-fluid profile_background mb-5">
        <div className="row">
          <div className="col-lg-12">
            <h1>
              {activeTab === "myprofile"
                ? "My Profile"
                : activeTab === "mycourse" 
                ? "My Courses"
                : activeTab === "purchasehistory"
                ? "Purchase History"
                : activeTab === "wishlist"
                ? "Wishlist"
                : activeTab === "mycertificates"
                ? "My Certificates"
                : null}
            </h1>
          </div>
        </div>
      </div>
 
      <div className="container myaccount_container">
        <div className="row">
          <div className="col-lg-12">
            <MyAccountNavbar
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
            {activeTab === "myprofile" ? <ProfileSection /> : null}

            {activeTab === "mycourse" ? <MyCoursesSection /> : null}

            {activeTab === "purchasehistory" ? (
              <PurchaseHistorySection />
            ) : null}
            {activeTab === "wishlist" ? <WishlistSection /> : null}
            {activeTab === "mycertificates" ? <MyCertificates /> : null}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Profile;
