import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "../Style/Payment.css";
import { PaymentApi } from "../APIS/MainApis";
import { clearcart } from "../store/cartSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

function Payment() { 

  const location = useLocation();
  const totalprice= location.state ? location.state.totalprice : null;


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  const cartFromRedux = useSelector((state) => state.cart.items);
  const courseIds = [];
  cartFromRedux.map((product) => courseIds.push(product.id));
  // console.log(courseIds);

  const sendPaymentDataToServer = async (paymentDetails) => {
    const dataToSend = {
      orderId: paymentDetails.id,
      courseId: courseIds,
      paymentType: "Paypal",
      transactionId: paymentDetails.purchase_units[0].payments.captures[0].id,
      status: paymentDetails.status,
      price: totalprice,
    };
    
    const response1 = await PaymentApi(dataToSend,token);
    console.log(response1);
    console.log(response1.data.message, "payment response");

    if (response1.data.message === "Course Purchased successfully") {
      dispatch(clearcart());
      Swal.fire({
        icon: "success",
        title:
          "Thank you for Purchasing Course from Safety And Training Academy",
        text: response1.data.message,
      });
      navigate("/");
    }
  };

  const initialOptions = {
    clientId:
      "ATVGPrvBoWlvRlRzzBPwPhqVkRXLV-W0CFpQ9crfkRxFiesECGE6ftY1b6hpaWQrhZFSZBtIMSqxjKRz",
    currency: "USD",
    intent: "capture",
  };

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "courses",
            amount: {
              value: totalprice,
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderId) => {
        console.log(orderId);
        return orderId;
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      if (details.status === "COMPLETED") {
        sendPaymentDataToServer(details);
      }
      console.log(details);
    });
  };

  const onError = (data, actions) => {
    console.error("Payment error:");
  };

  return (
    <div className="payment_container">
      <h1 className="text-start">Total Price: &#163; {totalprice}</h1>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
        />
      </PayPalScriptProvider> 
    </div>
  );
}

export default Payment;
