import React from "react";
import "../Style/Category.css";
import CategoryTabHome from "../Components/CategoryTabHome";
import { fetchCategoryApi } from "../APIS/MainApis";
import { useState, useEffect } from "react";
import CategoryComponent from "../Components/CategoryComponent";

function Category() {
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [activeTabCourses, setActiveTabCourses] = useState([]);

  useEffect(() => {
    fetchCategoryApi().then((data) => {
      if (data?.data?.category_data) {
        setCategories(data?.data?.category_data);
        setActiveTab(data?.data?.category_data[0]?.name);
      }
    });
  }, []);

  useEffect(() => {
    const selectedCategory = categories?.find(
      (category) => category.name === activeTab
    );

    if (selectedCategory) {
      setActiveTabCourses(selectedCategory.courses);
    } else {
      setActiveTabCourses([]);
    }
  }, [activeTab, categories]);

  return (
    <>
      <div className="container mb-5 py-5 category_container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <div className="category_heading">
              <h3 className="text-center fw-bold">
                Our Most Popular <span className="special">Courses</span>
              </h3>
              <p className="about text-center">
                The Safety and Training Learning Academy provide courses like
                workplace safety training, compliance essentials, safety
                leadership, behavioural safety and psychology, IOSH Managing
                Safely, Risk Assessment, First Aid and CPR, Fire Risk
                Assessment, Fire Safety, Confined Space Entry, Authorised Gas
                Tester and much more
              </p>
            </div>
          </div>
        </div>

        <CategoryTabHome
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          categories={categories}
        />
        <CategoryComponent courses={activeTabCourses} />
      </div>
    </>
  );
}

export default Category;
