import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../Style/Login.css";
import Swal from "sweetalert2";
import { fetchLoginApi } from "../APIS/MainApis";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PasswordResetForm from "./PasswordResetForm";
import { useDispatch } from "react-redux";
import { setUser } from "../store/userSlice";
import { useSelector } from "react-redux";
import { AddToCartCartApi } from "../APIS/MainApis";
import { fetchCartApi } from "../APIS/MainApis";
import { clearcart } from "../store/cartSlice";
import { add } from "../store/cartSlice";
import { fetchuserApi } from "../APIS/MainApis";
import { notlogin } from "../store/cartSlice";

const Login = ({ show, onClose, onSwitchToSignup, setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login_validationErrors, setlogin_ValidationErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [openForgotModal, setOpenForgotModal] = useState(false);

  const dispatch = useDispatch();

  const cartItems = useSelector((state) => state?.cart?.items);

  const addToCartOnLogin = async () => {
    const token = localStorage.getItem("token");
    try {
      if (cartItems) {
        const cartItemIds = cartItems.map((item) => item.id);
        console.log(cartItemIds, "login krne par");
        console.log(await AddToCartCartApi(token, cartItemIds));
      }

      dispatch(clearcart());
      fetchCartApi(token, (data) => {
        console.log(data);
        const response = data;
        for (const item of response?.data?.cartItems) {
          dispatch(add(item));
        }
      });
    } catch (error) {
      console.error("Error adding products to cart:", error);
    }
  };

  const login_resetForm = () => {
    setEmail("");
    setPassword("");
    setlogin_ValidationErrors({});
  };
  const login_handleClose = () => {
    onClose();
    login_resetForm();
  };
  const login_validateform = () => {
    const login_errors = {};
    if (!email) {
      login_errors.email = "Email cannot be empty";
    }
    if (!password) {
      login_errors.password = "Password cannot be empty";
    }
    setlogin_ValidationErrors(login_errors);
    return Object.keys(login_errors).length === 0;
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (login_validationErrors.email) {
      setlogin_ValidationErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (login_validationErrors.password) {
      setlogin_ValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (login_validateform()) {
      const userData = {
        email: email,
        password: password,
      };
      const response = await fetchLoginApi(userData);
      if (response?.data?.status === "success") {
        localStorage.setItem("token", response?.data?.data?.token);
        localStorage.removeItem("cart");
        localStorage.removeItem("userislogin");
        dispatch(clearcart());

        Swal.fire({
          icon: "success",
          title: "Signin Successful",
          text: response.data.message,
        });

        const token = localStorage.getItem("token");
        const response1 = await fetchuserApi(token);
        console.log(response1);
        if (
          response1 &&
          response1.data &&
          response1.data.data &&
          response1.data.data.user
        ) {
          dispatch(setUser(response1?.data?.data?.user));
        }
        login_resetForm();
        onClose();
        setIsLoggedIn(true);
        addToCartOnLogin();
      } else {
        Swal.fire({
          icon: "error",
          title: "Signin Failed",
          text: response.data.message,
        });
      }
    }
  };

  return (
    <>
      <PasswordResetForm
        setOpenForgotModal={setOpenForgotModal}
        openForgotModal={openForgotModal}
      />
      <Modal show={show} onHide={login_handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="login_heading_title fw-bold">
            Sign In
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label className="signin-label">Email</Form.Label>
              <Form.Control
                style={{
                  border: login_validationErrors.email ? "1px solid red" : "",
                  color: login_validationErrors.email ? "red" : "",
                }}
                placeholder="Enter Your Email"
                className="signin-formcontrol"
                type="text"
                value={email}
                onChange={handleEmailChange}
                autoComplete="username"
              />
              {login_validationErrors.email && (
                <div className="login_error">
                  {login_validationErrors.email}
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label className="signin-label">Password</Form.Label>
              <div className="password-input">
                <Form.Control
                  style={{
                    border: login_validationErrors.password
                      ? "1px solid red"
                      : "",
                    color: login_validationErrors.password ? "red" : "",
                  }}
                  placeholder="Enter Your Password"
                  className="signin-formcontrol"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  autoComplete="current-password"
                />
                <div
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
              {login_validationErrors.password && (
                <div className="login_error">
                  {login_validationErrors.password}
                </div>
              )}
            </Form.Group>
            <div className="d-flex align-items-center">
              <Button
                className="signin-button px-5"
                variant="primary"
                type="submit"
              >
                Sign In
              </Button>
            </div>
          </Form>
          <p className="signin-para">
            Don't Have a Account,{" "}
            <a href=" " onClick={onSwitchToSignup}>
              SignUp
            </a>
          </p>
          <p className="forgot-password-link">
            Have you forgotten your password?{" "}
            <span
              className="forgot-password"
              onClick={() => {
                setOpenForgotModal(true);
                onClose();
              }}
            >
              Forget Password
            </span>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
