import React from "react";
import Modal from "react-bootstrap/Modal";
import "../Style/InvoiceDetailsModal.css";



function InvoiceDetailsModal({ order, onClose }) {

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Invoice Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="invoice-content">
          <p>Transaction ID: {order.transactionid}</p>
          <p>Order ID: {order.orderId}</p>
          <p>Date: {order.date}</p>
          <p>Total Price: ${order.price}</p>
          <p>Payment Type: {order.paymentType}</p>
          <h3>Purchased Courses:</h3>
          <div className="text-start invoicedetails_courses">
            <ol>
              {order?.courses?.map((course, index) => (
                <li key={index}>
                  {course.course_name}, Price: ${course.course_price}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </Modal.Body>
      
    </Modal>
  );
}

export default InvoiceDetailsModal;
