import React from "react";
import "../Style/MyCertificates.css";
import jsPDF from "jspdf";

function MyCertificates() {

  const handleDownloadCertificate = () => {
    const pdf = new jsPDF();
    
   
    pdf.save("certificate.pdf");
  };
  return (
    <>
      <div className="table-responsive">
        <table className="mycertificates_table">
          <thead>
            <tr>
              <th>Course Name</th>
              <th>Completed On</th>
              <th>Download Certificate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Course 1</td>
              <td>1 Oct 2023</td>
              <td>
                <button className="mycertificates_button" onClick={handleDownloadCertificate}>Download</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default MyCertificates;
