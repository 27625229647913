import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/Navbar.css";
import { BiSearch } from "react-icons/bi"; 
import { AiOutlineShoppingCart } from "react-icons/ai";
import { RiArrowDownSLine } from "react-icons/ri";
import SignUp from "../Auth/Signup";
import Login from "../Auth/Login";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchCategoryApi } from "../APIS/MainApis";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearcart } from "../store/cartSlice";
import { clearUser } from "../store/userSlice";
import { searchCoursesApi } from "../APIS/MainApis"; 
import Sidebar from "./Sidebar";




function Navbar({ selectedCategory}) {
  
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [showSignup, setShowSignup] = useState(false);
  const [showSignin, setShowSignin] = useState(false);
  const [categoryDropdown, setCategoryDropdown] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const cartcount = useSelector((state) => state.cart.items);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim() === "") { 
      return;
    }

    try {
      const response = await searchCoursesApi(searchQuery);
      navigate("/searchcategory", {
        state: { searchResults: response.data.courses },
      });
    } catch (error) { 
      console.log("Error searching courses", error);
    }
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const AboutUsNavigate = () => {
    navigate("/aboutus");
    window.scrollTo(0, 0);
  };
  const ContactUsNavigate = () => {
    navigate("/contactus");
    window.scrollTo(0, 0);
  };
  const BlogNavigate = () => {
    navigate("/blog");
    window.scrollTo(0, 0);
  };

  const handleCategoryClick = (categoryId) => {
    navigate(`/allcourses/${categoryId}`);
  }; 

  useEffect(() => { 
    async function fetchCategoryDropdownData() { 
      try {
        const response = await fetchCategoryApi();
        if (response && response.data && response.data.category_data) {
          setCategoryDropdown(response.data.category_data);
        }
      } catch (error) {
        console.log("Error fetching Category Dropdown Data", error);
      }
    }
    fetchCategoryDropdownData();
  }, []);

  useEffect(() => {
    if(user)
    {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn,user]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const openSignupModal = (e) => {
    e.preventDefault();
    setShowSignup(true);
    setShowSignin(false);
  };

  const openSigninModal = () => {
    setShowSignin(true);
    setShowSignup(false);
  };

  const closeModals = () => {
    setShowSignup(false);
    setShowSignin(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    dispatch(clearcart());
    dispatch(clearUser());
    navigate("/");
  };

  return (
    <> 
      <Login
        show={showSignin}
        onClose={closeModals}
        onSwitchToSignup={openSignupModal}
        setIsLoggedIn={setIsLoggedIn}
      />
      <SignUp
        show={showSignup}
        onClose={closeModals}
        onSwitchToSignin={openSigninModal}
      />
      
      <div className="container navbar-light py-2 navbar_shadow navbar_container">
        <div className="row align-items-center ">
          <div className="col-6 col-md-4 col-lg-4 col-xl-2 col-xxl-2 text-start">
            <img
              onClick={() => navigate("/")}
              className="navbar_logo"
              src="../company_logo.png"
              alt="Company Logo"
            ></img>
          </div>
          <div className="col-6 col-md-8 navbar_toggle text-end">
          <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation" 
                onClick={()=>setIsSidebarOpen(!isSidebarOpen)}
              >
                <span className="navbar-toggler-icon"></span>
          </button>
          <Sidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} categoryDropdown={categoryDropdown} selectedCategory={selectedCategory} handleSearch={handleSearch}/> 
          </div>
          <div className="col-6 col-md-8 col-lg-8 col-xl-4 col-xxl-4 navbar_left">
            <div>
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <span
                    className="nav-link dropdown-toggle navbar_left_link"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Category
                  </span>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    {categoryDropdown.map((category, i) => (
                      <span
                        key={i}
                        className={`dropdown-item ${
                          selectedCategory === category.name ? "active" : ""
                        }`}
                        onClick={() => handleCategoryClick(category.id)}
                      >
                        {category.name}
                      </span>
                    ))}
                  </div>
                </li>

                <li onClick={AboutUsNavigate} className="nav-item">
                  <span className="nav-link navbar_left_link">About Us</span>
                </li>

                <li onClick={ContactUsNavigate} className="nav-item">
                  <span className="nav-link navbar_left_link">Contact Us</span>
                </li>

                <li onClick={BlogNavigate} className="nav-item">
                  <span href="" className="nav-link navbar_left_link">
                    Blogs
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 mt-3 mt-lg-0 navbar_search">
            <form className="d-flex nav-search w-100" onSubmit={handleSearch}>
              <BiSearch />
              <input
                type="text"  
                className="form-control"
                placeholder="Search Courses" 
                aria-label="Search"
                aria-describedby="search-icon"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>

          <div className="col-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 mt-3 mt-lg-0 navbar_right">
            {isLoggedIn ? (
              <>
                <div className="navbar_accountbuttontoggle">
                  <button
                    className="navbar_accountbutton"
                    onClick={toggleDropdown}
                  >
                    Account <RiArrowDownSLine />
                  </button>
                  {isDropdownOpen && (
                    <div className="navbar_dropdown"> 
                      <ul>
                        <li onClick={() => navigate("/myaccount")}>
                          My Account
                        </li> 
                        <li onClick={handleLogout}>Logout</li>
                      </ul>
                    </div>
                  )}
                </div>
                <div className="navbar_cart" onClick={() => navigate("/cart")}>
                  <AiOutlineShoppingCart className="navbar_carticon" />
                  {
                    cartcount?.length===0
                    ?
                    null:
                    <span className="navbar_cartcounts">{cartcount?.length}</span>
                  }
                </div>
              </>
            ) : (
              <>
                <button
                  onClick={openSigninModal}
                  className="navbar_login_button"
                >
                  LogIn
                </button>
                <button
                  onClick={openSignupModal}
                  className="navbar_signup_button"
                >
                  SignUp
                </button>
                <div className="navbar_cart" onClick={() => navigate("/cart")}>
                  <AiOutlineShoppingCart className="navbar_carticon" />
                  {
                    cartcount?.length===0
                    ?
                    null:
                    <span className="navbar_cartcounts">{cartcount?.length}</span>
                  }
                  
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
