import React from "react";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { RxVideo } from "react-icons/rx";
import "../Style/CategoryLearning2Content.css";



function CategoryLearning2Content({ sections = [], id }) {

  return (
    <>
      <MDBAccordion>
        {sections?.map((section, index) => (
          <MDBAccordionItem
            key={index}
            collapseId={index + 1}
            headerTitle={section.section_title}
          >
            {section.lessons?.map((lesson, lessonIndex) => (
              <div
                key={lessonIndex}
                className="d-flex justify-content-between align-items-center mb-2"
              >
                <div className="clcontent_menu d-flex text-start align-items-center">
                  <div className="cl2content_videoicon">
                    <RxVideo />
                  </div>
                  <div className="cl2_title">{lesson.lesson_title}</div>
                </div>
                <div className="cl2_time">1m 17s</div> 
              </div>
            ))}
          </MDBAccordionItem>
        ))}
      </MDBAccordion>
    </>
  );
}

export default CategoryLearning2Content;
