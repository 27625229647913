import React from 'react'
import { BiSearch } from "react-icons/bi"; 
import { useNavigate } from "react-router-dom";
import {useState}  from 'react';
import {searchCoursesApi} from "../APIS/MainApis"


function ContentsMenuNavbar() {

  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const navbarStyle = {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  };
  
  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim() === "") {
      return;
    }

    try {
      const response = await searchCoursesApi(searchQuery);
      navigate("/searchcategory", {
        state: { searchResults: response?.data?.courses },
      });
    } catch (error) {
      console.log("Error searching courses", error);
    }
  };

  const AboutUsNavigate = () => {
    navigate("/aboutus");
    window.scrollTo(0, 0);
  };
  const ContactUsNavigate = () => {
    navigate("/contactus");
    window.scrollTo(0, 0);
  };
  const BlogNavigate = () => {
    navigate("/blog");
    window.scrollTo(0, 0);
  };
  return ( 
    <div className="contentmenu_navbar" style={navbarStyle}>
      <div className="container-fluid navbar-light  py-2">
        <div className="row d-flex align-items-center ">
          <div className="col-md-2 d-flex justify-content-start">
            <img
              onClick={() => navigate("/")}
              className="navbar_logo"
              src="../company_logo.png"
              alt="Company Logo"
            ></img>
          </div>
          <div className="col-md-4 navbar_left">
            <ul className="navbar-nav">
              <li onClick={AboutUsNavigate} className="nav-item">
                <span className="nav-link">About Us</span>
              </li>

              <li onClick={ContactUsNavigate} className="nav-item">
                <span className="nav-link">Contact Us</span>
              </li>

              <li onClick={BlogNavigate} className="nav-item">
                <span href="" className="nav-link">
                  Blogs
                </span>
              </li>
            </ul>
          </div>

          <div className="col-md-3 navbar_search m-0 p-0">
            <form className="d-flex nav-search w-100" onSubmit={handleSearch}>
              <BiSearch />
              <input
                type="text"
                className="form-control"
                placeholder="Search Courses"
                aria-label="Search"
                aria-describedby="search-icon"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentsMenuNavbar