import React, { useEffect, useState } from "react";
import "../Style/StudentMessage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { fetchStudentMessageApi } from "../APIS/MainApis";

function StudentMessage() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    async function fetchStudentMessageData() {
      try {
        const response = await fetchStudentMessageApi();
        if (response && response.data && response.data.testimonial_data) {
          setTestimonials(response.data.testimonial_data);
        }
      } catch (error) {
        console.log("Error fetching student messages", error);
      }
    }
    fetchStudentMessageData();
  }, []);

  const slickSettings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="message">
      <div className="container py-5 studentmessage_container">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div>
              <h3 className="fw-bold text-center">
                What Student's <span className="special">Say</span>
              </h3>
            </div>
            <div>
              <p className="text-center mb-4">
                These are some reviews shared by our users which opt our courses
                and get benefits from them..
              </p>
            </div> 
 
            <Slider className="slider-container"{...slickSettings}>
              {testimonials.map((testimonial, i) => (
                <div key={i} className="student_message_card">
                  <div className="slide-content-wrapper">
                    <div
                      className="card_text text-start mb-4"
                      dangerouslySetInnerHTML={{
                        __html: testimonial.test_desc,
                      }}
                    ></div>
                    <div className="card_who text-start d-flex">
                      <img
                        className="card_who_image"
                        src={testimonial.image}
                        alt="who"
                      />
                      <div className="student_info">
                        <p className="student_name">{testimonial.name}</p>
                        <p className="student_designation">
                          {testimonial.desig}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentMessage;
