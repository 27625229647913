import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../Style/Signup.css";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";



const Signup = ({ show, onClose, onSwitchToSignin }) => { 


  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);

  
  const resetForm = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setValidationErrors({});
  };
  const handleClose = () => {
    onClose();
    resetForm();
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setShowPassword(false);
    if (validationErrors.username) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, username: "" }));
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (validationErrors.email) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (validationErrors.password) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (validationErrors.confirmPassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {};
    const usernameRegex = /^[a-zA-Z0-9_]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!usernameRegex.test(username)) {
      errors.username = "Invalid username format";
    }

    if (!emailRegex.test(email)) {
      if (!email.includes("@")) {
        errors.email = "Email address must contain '@' and '.' ";
      } else if (!email.includes(".")) {
        errors.email = "Email address must contain '@' and '.' ";
      } else if (email.indexOf("@") === 0) {
        errors.email = "Missing username before '@'";
      } else if (email.indexOf("@") === email.length - 1) {
        errors.email = "Missing domain after '@'";
      } else if (email.indexOf(".") === email.length - 1) {
        errors.email = "Missing domain after '.'";
      } else if (email.split("@").length !== 2) {
        errors.email = "Email should contain only one '@' symbol";
      } else {
        errors.email = "Invalid email format";
      }
    }

    if (!passwordRegex.test(password)) {
      errors.password =
        "Password must be at least 8 characters and include at least one lowercase letter, one uppercase letter, one digit, and one special character.";
    }

    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    if (!username) {
      errors.username = "Username cannot be empty";
    }

    if (!email) {
      errors.email = "Email cannot be empty";
    }

    if (!password) {
      errors.password = "Password cannot be empty";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm password cannot be empty";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const userData = {
        name: username,
        email: email,
        password: password,
        password_confirmation: confirmPassword,
      };
      try {
        const response = await axios.post(
          "https://admin.tsata.co.uk/api/auth/register",
          userData
        );
        console.log("Signup successful:", response.data);
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "Signup Successful", 
            text: response.data.message,
          });

          onClose();
          resetForm();
          onSwitchToSignin();
        } else {
          Swal.fire({
            icon: "error",
            title: "Signup Failed",
            text: response.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Signup Failed",
          text: "An error occurred during signup. Please try again later.",
        });
        console.error("Signup error:", error);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="signup_heading_title">Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="username">
              <Form.Label className="signup-label">Username</Form.Label>
              <Form.Control
                style={{
                  border: validationErrors.username ? "1px solid red" : "",
                  color: validationErrors.username ? "red" : "",
                }}
                placeholder="Enter Your Name"
                className="signup-formcontrol"
                type="text"
                value={username}
                onChange={handleUsernameChange}
              />
              {validationErrors.username && (
                <div className="signup_error">{validationErrors.username}</div>
              )}
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label className="signup-label">Email</Form.Label>
              <Form.Control
                style={{
                  border: validationErrors.email ? "1px solid red" : "",
                  color: validationErrors.email ? "red" : "",
                }}
                placeholder="Enter Your Email"
                className="signup-formcontrol"
                type="text"
                value={email}
                onChange={handleEmailChange}
              />
              {validationErrors.email && (
                <div className="signup_error">{validationErrors.email}</div>
              )}
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label className="signup-label">Password</Form.Label>
              <div className="password-input">
                <Form.Control
                  style={{
                    border: validationErrors.password ? "1px solid red" : "",
                    color: validationErrors.password ? "red" : "",
                  }}
                  placeholder="Enter Your Password"
                  className="signup-formcontrol"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                />
                <div
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
              {validationErrors.password && (
                <div className="signup_error">{validationErrors.password}</div>
              )}
            </Form.Group>
            <Form.Group controlId="confirmpassword">
              <Form.Label className="signup-label">Confirm Password</Form.Label>
              <div className="confirmpassword-input">
                <Form.Control
                  style={{
                    border: validationErrors.confirmPassword
                      ? "1px solid red"
                      : "",
                    color: validationErrors.confirmPassword ? "red" : "",
                  }}
                  placeholder="Enter Your Confirm Password"
                  className="signup-formcontrol"
                  type={showconfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <div
                  className="confirmpassword-toggle"
                  onClick={() => setshowconfirmPassword(!showconfirmPassword)}
                >
                  {showconfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
              {validationErrors.confirmPassword && (
                <div className="signup_error">
                  {validationErrors.confirmPassword}
                </div>
              )}
            </Form.Group>
            <div className="d-flex align-items-center">
              <Button
                className="signup-button px-5 mt-4"
                variant="primary"
                type="submit"
              >
                Sign Up
              </Button>
            </div>
          </Form>

          <p className="signup-para">
            If you already have an account,{" "}
            <a
              href="#"
              onClick={() => {
                onSwitchToSignin();
                resetForm();
              }}
            >
              Login
            </a>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Signup;
