import React from 'react'
import "../Style/Reason.css"
import {MdOutlineLocalLibrary} from "react-icons/md"
import {MdOutlineSchool} from "react-icons/md"
import {AiOutlineLaptop} from "react-icons/ai"
function Reason() {
  return ( 
    <>
   <div className="reason-container py-5">
     <div className="container"> 
        <div className="row">
            <h3 className='text-white text-center fw-bold'>Why Learn With Our Courses ?</h3>
            <p className='text-white text-center mb-4'>Discover the compelling reasons to choose our courses for your educational journey.</p>

            <div className="col-12 col-lg-4">
                <div className='d-flex'> 
                    <div className='reason-about'>
                    <p className='text-white fw-bold fs-1'><MdOutlineLocalLibrary/></p>
                    <p className='text-white fw-bold fs-4'>01. Learn</p>
                    <p className='text-white'> Gain knowledge from our comprehensive courses. We provide top-notch educational resources and expert instructors to help you learn effectively. Whether you're a beginner or an experienced learner, our courses cater to your educational needs.</p>
                </div>
                <p className="vertical-line"></p>
                </div>
                <p className='reason_horizontalline'></p>
            </div>

            <div className="col-12 col-lg-4"> 
                 <div className='d-flex'>
                    <div className='reason-about'>
                    <p className='text-white fw-bold fs-1'><MdOutlineSchool/></p>
                    <p className='text-white fw-bold fs-4'>02. Graduate</p>
                    <p className='text-white'>Propel your career forward with our courses. We provide a structured learning path that ensures you graduate with the skills and qualifications needed to excel in your chosen field. Prepare for a bright future with us.</p>
                </div>
                <p className="vertical-line"></p>
                </div>
                <p className='reason_horizontalline'></p>
            </div>

            <div className="col-12 col-lg-4">
                 <div className='d-flex'>
                    <div className='reason-about'>
                    <p className='text-white fw-bold fs-1'><AiOutlineLaptop/></p>
                    <p className='text-white fw-bold fs-4'>03. Work</p>
                    <p className='text-white'>Apply what you learn directly to your work. Our courses focus on practical skills and real-world applications. You'll be ready to tackle challenges and contribute meaningfully in your professional life.</p>
                </div>
                </div>
                <p className='reason_horizontalline'></p>
            </div>

        </div>
    </div>
   </div>

    </>
  )
}

export default Reason