import React from "react";
import "../Style/DataPolicy.css";
import Navbar from "./Navbar";
import { TermsAndConditionApi } from "../APIS/MainApis";
import { useEffect, useState } from "react";
import Footer from "./Footer";

function DataPolicy() {
  // const [termsandconditionData, settermsandconditionData] = useState([]);

  // useEffect(() => {
  //   async function fetchtermsandcondition() {
  //     const response = await TermsAndConditionApi();
  //     settermsandconditionData(response.data.data.terms_data[0]);
  //   }
  //   fetchtermsandcondition();
  // }, []);

  return (
    <div>
      <Navbar />
      <div className="container-fluid  p-0 datapolicy_container">
        <div className="row">
          <div className="col-lg-12 termscondition_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 termscondition_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">Data Policy</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container termscondition_data datapolicy_container py-5">
        <div className="row">
          <div className="col-lg-12">
            <h5 className="text-start mb-2">Introduction: </h5>
            <p className="text-start mb-2">
              This Policy sets out the obligations of The Safety and Training
              Academy (“the Company”) regarding data protection and the rights
              of customers and business contacts (“data subjects”) in respect of
              their personal data under Data Protection Act 2018 (Formally EU
              Regulation 2016/679 General Data Protection Regulation (“GDPR”)).{" "}
            </p>
            <p className="text-start mb-2">
              The Data Protection Act 2018 defines “personal data” as any
              information relating to an identified or identifiable natural
              person (a “data subject”); an identifiable natural person is one
              who can be identified, directly or indirectly, in particular by
              reference to an identifier such as a name, an identification
              number, location data, an online identifier, or to one or more
              factors specific to the physical, physiological, genetic, mental,
              economic, cultural, or social identity of that natural person.
            </p>
            <p className="text-start mb-2">
              This Policy sets the Company’s obligations regarding the
              collection, processing, transfer, storage, and disposal of
              personal data. The procedures and principles set out herein must
              be followed at all times by the Company, its employees, agents,
              contractors, or other parties working on behalf of the Company.
            </p>
            <p className="text-start mb-2">
              The Company is committed not only to the letter of the law, but
              also to the spirit of the law and places high importance on the
              correct, lawful, and fair handling of all personal data,
              respecting the legal rights, privacy, and trust of all individuals
              with whom it deals.
            </p>
            <h5 className="text-start mb-2">The Data Protection Principles</h5>
            <p className="text-start mb-2">
              This Policy aims to ensure compliance with the Data Protection Act
              2018. The Data Protection Act 2018 sets out the following
              principles with which any party handling personal data must
              comply. All personal data must be:
            </p>
            <div className="text-start mb-2">
              <ol>
                <li>
                  Processed lawfully, fairly, and in a transparent manner in
                  relation to the data subject.
                </li>
                <li>
                  Adequate, relevant, and limited to what is necessary in
                  relation to the purposes for which it is processed.
                </li>
                <li>
                  Collected for specified, explicit, and legitimate purposes and
                  not further processed in a manner that is incompatible with
                  those purposes. Further processing for archiving purposes in
                  the public interest, scientific or historical research
                  purposes or statistical purposes shall not be considered to be
                  incompatible with the initial purposes.
                </li>
                <li>
                  Accurate and, where necessary, kept up to date. Every
                  reasonable step must be taken to ensure that personal data
                  that is inaccurate, having regard to the purposes for which it
                  is processed, is erased, or rectified without delay.
                </li>
                <li>
                  Kept in a form which permits identification of data subjects
                  for no longer than is necessary for the purposes for which the
                  personal data is processed. Personal data may be stored for
                  longer periods insofar as the personal data will be processed
                  solely for archiving purposes in the public interest,
                  scientific or historical research purposes, or statistical
                  purposes, subject to implementation of the appropriate
                  technical and ©The Safety and Training Academy Ltd
                  www.thesafetytrainingcompany.com Temp-003-Data Protection
                  Policy organisational measures required by the Data Protection
                  Act 2018 in order to safeguard the rights and freedoms of the
                  data subject.
                </li>
                <li>
                  Processed in a manner that ensures appropriate security of the
                  personal data, including protection against unauthorised or
                  unlawful processing and against accidental loss, destruction,
                  or damage, using appropriate technical or organisational
                  measures.
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">The Rights of Data Subjects</h5>
            <div className="text-start mb-2">
              <ol>
                <li>
                  The Data Protection Act 2018 sets out the following rights
                  applicable to data subjects
                </li>
                <li>The right to be informed’</li>
                <li>The right of access,</li>
                <li>The right to rectification,</li>
                <li>
                  The right to erasure (also known as the ‘right to be
                  forgotten’),
                </li>
                <li>The right to restrict processing</li>
                <li>The right to data portability,</li>
                <li>The right to object; and</li>
                <li>
                  Rights with respect to automated decision-making and
                  profiling.
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">
              Lawful, Fair, and Transparent Data Processing
            </h5>
            <p className="text-start mb-2">
              The Data Protection Act 2018 seeks to ensure that personal data is
              processed lawfully, fairly, and transparently, without adversely
              affecting the rights of the data subject. The Data Protection Act
              2018 states that processing of personal data shall be lawful if at
              least one of the following applies:
            </p>
            <div className="text-start mb-2">
              <ol>
                <li>
                  The data subject has given consent to the processing of their
                  personal data for one or more specific purposes;
                </li>
                <li>
                  The processing is necessary for the performance of a contract
                  to which the data subject is a party, or in order to take
                  steps at the request of the data subject prior to entering
                  into a contract with them;
                </li>
                <li>
                  The processing is necessary to protect the vital interests of
                  the data subject or of another natural person;
                </li>
                <li>
                  The processing is necessary for the performance of a task
                  carried out in the public interest or in the exercise of
                  official authority vested in the data controller; or
                </li>
                <li>
                  The processing is necessary for the purposes of the legitimate
                  interests pursued by the data controller or by a third party,
                  except where such interests are overridden by the fundamental
                  rights and freedoms of the data subject which require
                  protection of personal data, in particular where the data
                  subject is a child.
                </li>
                <li>
                  If the personal data in question is “special category data”
                  (also known as “sensitive personal data”) (for example, data
                  concerning the data subject’s health), at least one of the
                  following conditions must be met:
                </li>
                <li>
                  The data subject has given their explicit consent to the
                  processing of such data for one or more specified purposes
                  (unless EU or EU Member State law prohibits them from doing
                  so);
                </li>
                <li>
                  The processing is necessary to protect the vital interests of
                  the data subject or of another natural person where the data
                  subject is physically or legally incapable of giving consent;
                </li>
                <li>
                  The processing relates to personal data which is clearly made
                  public by the data subject;
                </li>
                <li>
                  The processing is necessary for the conduct of legal claims or
                  whenever courts are acting in their judicial capacity;
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">
              Specified, Explicit, and Legitimate Purposes
            </h5>
            <p className="text-start mb-2">
              The Company collects and processes the personal data set out in
              this Policy.
            </p>
            <p className="text-start mb-2">This includes:</p>
            <div className="text-start mb-2">
              <ol>
                <li>Personal data collected directly from data subjects OR</li>
                <li>Personal data obtained from third parties.</li>
                <li>
                  The Company only collects, processes, and holds personal data
                  for the specific purposes set out in this Policy (or for other
                  purposes expressly permitted by the Data Protection Act 208).
                </li>
                <li>
                  Data subjects are kept informed at all times of the purpose or
                  purposes for which the Company uses their personal data
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">
              Adequate, Relevant, and Limited Data Processing
            </h5>
            <p className="text-start mb-2">
              The Company will only collect and process personal data for and to
              the extent necessary for the specific purpose or purposes of which
              data subjects have been informed (or will be informed).
            </p>
            <h5 className="text-start mb-2">
              Accuracy of Data and Keeping Data Up-to-Date
            </h5>
            <div className="text-start mb-2">
              <ol>
                <li>
                  The Company shall ensure that all personal data collected,
                  processed, and held by it is kept accurate and up-to-date.
                  This includes, but is not limited to, the rectification of
                  personal data at the request of a data subject.
                </li>
                <li>
                  {" "}
                  When personal data is no longer required, all reasonable steps
                  will be taken to erase or otherwise dispose of it without
                  delay.
                </li>
                <li>
                  For full details of the Company’s approach to data retention,
                  including retention periods for specific personal data types
                  held by the Company, please refer to our Data Retention
                  Policy.
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">Secure Processing</h5>
            <p className="text-start mb-2">
              The Company shall ensure that all personal data collected, held,
              and processed is kept secure and protected against unauthorised or
              unlawful processing and against accidental loss, destruction, or
              damage. Further details of the technical and organisational
              measures which shall be taken are provided later in this Policy.
            </p>
            <h5 className="text-start mb-2">
              Accountability and Record-Keeping
            </h5>
            <p className="text-start mb-2">
              The Company’s Data Protection Officer is Wayne M Sundborg,{" "}
            </p>
            <p className="text-start mb-2">
              <b>E-Mail:</b> wsundborg@thesafetytrainingcompany.com{" "}
            </p>
            <p className="text-start mb-2">
              <b>Tel:</b> 0208 144 5897 - 07498 682913
            </p>
            <p className="text-start mb-2">
              The Data Protection Officer shall be responsible for overseeing
              the implementation of this Policy and for monitoring compliance
              with this Policy, the Company’s other data protection-related
              policies, and with the Data Protection Act 2018 and other
              applicable data protection legislation.
            </p>
            <div className="text-start mb-2">
              <ol>
                <li>
                  The Company shall keep written internal records of all
                  personal data collection, holding, and processing, which shall
                  incorporate the following information:
                </li>
                <li>
                  The name and details of the Company, its Data Protection
                  Officer, and any applicable third-party data processors;
                </li>
                <li>
                  The purposes for which the Company collects, holds, and
                  processes personal data;
                </li>
                <li>
                  Details of the categories of personal data collected, held,
                  and processed by the Company, and the categories of data
                  subject to which that personal data relates;
                </li>
                <li>
                  Details of any transfers of personal data to non-EEA countries
                  including all mechanisms and security safeguards;
                </li>
                <li>
                  Details of how long personal data will be retained by the
                  Company; and
                </li>
                <li>
                  Detailed descriptions of all technical and organisational
                  measures taken by the Company to ensure the security of
                  personal data.
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">
              Data Protection Impact Assessments
            </h5>
            <div className="text-start mb-2">
              <ol>
                <li>
                  The Company shall carry out Data Protection Impact Assessments
                  for any and all new projects and/or new uses of personal data.
                  <li>
                    Data Protection Impact Assessments shall be overseen by the
                    Data Protection Officer and shall address the following:
                  </li>
                  <div>
                    <ul>
                      <li>
                        • The type(s) of personal data that will be collected,
                        held, and processed;
                      </li>
                      <li>
                        • The purpose(s) for which personal data is to be used;
                      </li>
                      <li>• The Company’s objectives;</li>
                      <li>• How personal data is to be used;</li>
                      <li>
                        • The parties (internal and/or external) who are to be
                        consulted;
                      </li>
                      <li>
                        • The necessity and proportionality of the data
                        processing with respect to the
                      </li>
                      <li>• purpose(s) for which it is being processed;</li>
                      <li>• Risks posed to data subjects;</li>
                      <li>• Risks posed both within and to the Company; and</li>
                      <li>
                        • Proposed measures to minimize and handle identified
                        risks.
                      </li>
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">Keeping Data Subjects Informed</h5>
            <p className="text-start mb-2">
              The Company shall provide the information set out in section (i)
              below to every data subject:
            </p>
            <p className="text-start mb-2">
              Where personal data is collected directly from data subjects,
              those data subjects will be informed of its purpose at the time of
              collection; and where personal data is obtained from a third
              party, the relevant data subjects will be informed of its purpose:
            </p>
            <div className="text-start mb-2">
              <ol>
                <li>
                  if the personal data is used to communicate with the data
                  subject, when the first communication is made; or
                </li>
                <li>
                  if the personal data is to be transferred to another party,
                  before that transfer is made; or
                </li>
                <li>
                  {" "}
                  as soon as reasonably possible and in any event not more than
                  one month after the personal data is obtained.
                </li>
                <p className="text-start mb-2">
                  The following information shall be provided:
                </p>
                <li>
                  Details of the Company including, but not limited to, the
                  identity of its Data Protection Officer;
                </li>
                <li>
                  The purpose(s) for which the personal data is being collected
                  and will be processed (as detailed in this Policy) and the
                  legal basis justifying that collection and processing;
                </li>
                <li>
                  {" "}
                  Where applicable, the legitimate interests upon which the
                  Company is justifying its collection and processing of the
                  personal data;
                </li>
                <li>
                  Where the personal data is not obtained directly from the data
                  subject, the categories of personal data collected and
                  processed;
                </li>
                <li>
                  Where the personal data is to be transferred to one or more
                  third parties, details of those parties;
                </li>
                <li>
                  {" "}
                  Where the personal data is to be transferred to a third party
                  that is located outside of the European Economic Area (the
                  “EEA”), details of that transfer, including but not limited to
                  the safeguards in place,
                </li>

                <div className="text-start mb-2">
                  <ul>
                    <li>• Details of data retention;</li>
                    <li>
                      • Details of the data subject’s rights under the Data
                      Protection Act 2018;
                    </li>
                    <li>
                      •Details of the data subject’s right to withdraw their
                      consent to the Company’s processing of their personal data
                      at any time;
                    </li>
                    <li>
                      • Details of the data subject’s right to complain to the
                      Information Commissioner’s Office (the “supervisory
                      authority” under the Data Protection Act 2018);
                    </li>
                    <li>
                      • Where applicable, details of any legal or contractual
                      requirement or obligation necessitating the collection and
                      processing of the personal data and details of any
                      consequences of failing to provide it; and
                    </li>
                    <li>
                      • Details of any automated decision-making or profiling
                      that will take place using the personal data, including
                      information on how decisions will be made, the
                      significance of those decisions, and any consequences.
                    </li>
                  </ul>
                </div>
              </ol>
            </div>

            <h5 className="text-start mb-2">Data Subject Access</h5>
            <div className="text-start mb-2">
              <ol>
                <li>
                  Data subjects may make subject access requests (“SARs”) at any
                  time to find out more about the personal data which the
                  Company holds about them, what it is doing with that personal
                  data, and why.
                </li>
                <li>
                  Data subjects wishing to make a SAR may do so in writing,
                  using the Company’s Subject Access Request Form, or other
                  written communication. SARs should be addressed to the
                  Company’s Data Protection Officer at The Safety and Training
                  Academy Ltd., 128 City Road, London, EC1V 2NX, Tel: 0208 144
                  5897 - 07498 682913 Email:
                  wsundborg@thesafetytrainingcompany.com{" "}
                </li>
                <li>
                  Responses to SARs shall normally be made within one month of
                  receipt, however this may be extended by up to two months if
                  the SAR is complex and/or numerous requests are made. If such
                  additional time is required, the data subject shall be
                  informed.
                </li>
                <li>
                  All SARs received shall be handled by the Company’s Data
                  Protection Officer.
                </li>
                <li>
                  The Company does not charge a fee for the handling of normal
                  SARs. The Company reserves the right to charge reasonable fees
                  for additional copies of information that has already been
                  supplied to a data subject, and for requests that are
                  manifestly unfounded or excessive, particularly where such
                  requests are repetitive.
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">Rectification of Personal Data</h5>
            <div className="text-start mb-2">
              <ol>
                <li>
                  Data subjects have the right to require the Company to rectify
                  any of their personal data that is inaccurate or incomplete.
                </li>
                <li>
                  The Company shall rectify the personal data in question, and
                  inform the data subject of that rectification, within one
                  month of the data subject informing the Company of the issue.
                  The period can be extended by up to two months in the case of
                  complex requests. If such additional time is required, the
                  data subject shall be informed.
                </li>
                <li>
                  In the event that any affected personal data has been
                  disclosed to third parties, those parties shall be informed of
                  any rectification that must be made to that personal data.
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">Erasure of Personal Data</h5>
            <p className="text-start mb-2">
              Data subjects have the right to request that the Company erases
              the personal data it holds about them in the following
              circumstances:
            </p>
            <div className="text-start mb-2">
              <ol>
                <li>
                  It is no longer necessary for the Company to hold that
                  personal data with respect to the purpose(s) for which it was
                  originally collected or processed;
                </li>
                <li>
                  The data subject wishes to withdraw their consent to the
                  Company holding and processing their personal data;
                </li>
                <li>
                  The data subject objects to the Company holding and processing
                  their personal data (and there is no overriding legitimate
                  interest to allow the Company to continue doing so),
                </li>
                <li>The personal data has been processed unlawfully;</li>
                <li>
                  The personal data needs to be erased in order for the Company
                  to comply with a particular legal obligation.
                </li>
              </ol>
            </div>
            <p className="text-start mb-2">
              Unless the Company has reasonable grounds to refuse to erase
              personal data, all requests for erasure shall be complied with,
              and the data subject informed of the erasure, within one month of
              receipt of the data subject’s request. The period can be extended
              by up to two months in the case of complex requests. If such
              additional time is required, the data subject shall be informed.
            </p>
            <p className="text-start mb-2">
              In the event that any personal data that is to be erased in
              response to a data subject’s request has been disclosed to third
              parties, those parties shall be informed of the erasure (unless it
              is impossible or would require disproportionate effort to do so).
            </p>
            <h5 className="text-start mb-2">
              Restriction of Personal Data Processing
            </h5>
            <p className="text-start mb-2">
              Data subjects may request that the Company ceases processing the
              personal data it holds about them. If a data subject makes such a
              request, the Company shall retain only the amount of personal data
              concerning that data subject (if any) that is necessary to ensure
              that the personal data in question is not processed further.
            </p>
            <p className="text-start mb-2">
              In the event that any affected personal data has been disclosed to
              third parties, those parties shall be informed of the applicable
              restrictions on processing it (unless it is impossible or would
              require disproportionate effort to do so).
            </p>
            <h5 className="text-start mb-2">
              Objections to Personal Data Processing
            </h5>
            <p className="text-start mb-2">
              Data subjects have the right to object to the Company processing
              their personal data based on legitimate interests, direct
              marketing (including profiling) and processing for scientific
              and/or historical research and statistics purposes.
            </p>
            <p className="text-start mb-2">
              Where a data subject objects to the Company processing their
              personal data based on its legitimate interests, the Company shall
              cease such processing immediately, unless it can be demonstrated
              that the Company’s legitimate grounds for such processing override
              the data subject’s interests, rights, and freedoms, or that the
              processing is necessary for the conduct of legal claims
            </p>
            <p className="text-start mb-2">
              Where a data subject objects to the Company processing their
              personal data for direct marketing purposes, the Company shall
              cease such processing immediately.
            </p>
            <p className="text-start mb-2">
              Where a data subject objects to the Company processing their
              personal data for scientific and/or historical research and
              statistics purposes, the data subject must, under the Data
              Protection Act 2018, “demonstrate grounds relating to his or her
              particular situation”. The Company is not required to comply if
              the research is necessary for the performance of a task carried
              out for reasons of public interest.
            </p>
            <h5 className="text-start mb-2">
              Data Security - Transferring Personal Data and Communications
            </h5>
            <p className="text-start mb-2">
              The Company shall ensure that the following measures are taken
              with respect to all communications and other transfers involving
              personal data:
            </p>
            <div className="text-start mb-2">
              <ol>
                <li>
                  All emails containing personal data must be encrypted using
                  Encryption software;
                </li>
                <li>
                  All emails containing personal data must be marked
                  “confidential”;
                </li>
                <li>
                  Personal data may be transmitted over secure networks only;
                  transmission over unsecured networks is not permitted in any
                  circumstances;
                </li>
                <li>
                  Personal data may not be transmitted over a wireless network
                  if there is a wired alternative that is reasonably
                  practicable;
                </li>
                <li>
                  Personal data contained in the body of an email, whether sent
                  or received, should be copied from the body of that email and
                  stored securely. The email itself should be deleted. All
                  temporary files associated therewith should also be deleted
                  using deletion software;
                </li>
                <li>
                  Where personal data is to be transferred in hardcopy form it
                  should be passed directly to the recipient or sent using Royal
                  Mail Registered or 1st or 2nd Class Signed For post; and
                </li>
                <li>
                  All personal data to be transferred physically, whether in
                  hardcopy form or on removable electronic media shall be
                  transferred in a suitable container marked “confidential”.
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">Data Security - Storage</h5>
            <p className="text-start mb-2">
              The Company shall ensure that the following measures are taken
              with respect to the storage of personal data:
            </p>
            <div className="text-start mb-2">
              <ol>
                <li>
                  All electronic copies of personal data should be stored
                  securely using passwords and data encryption;
                </li>
                <li>
                  All hardcopies of personal data, along with any electronic
                  copies stored on physical, removable media should be stored
                  securely in a locked box, drawer, cabinet, or similar;
                </li>
                <li>
                  All personal data stored electronically should be backed up at
                  least daily with backups stored onsite. All backups should be
                  encrypted using data encryption’
                </li>
                <li>
                  No personal data should be stored on any mobile device
                  (including, but not limited to, laptops, tablets, and
                  smartphones), whether such device belongs to the Company or
                  ©The Safety and Training Academy Ltd
                  www.thesafetytrainingcompany.com Temp-003-Data Protection
                  Policy otherwise without the formal written approval of the
                  Data Protection Officer and, in the event of such approval,
                  strictly in accordance with all instructions and limitations
                  described at the time the approval is given, and for no longer
                  than is absolutely necessary; and
                </li>
                <li>
                  No personal data should be transferred to any device
                  personally belonging to an employee and personal data may only
                  be transferred to devices belonging to agents, contractors, or
                  other parties working on behalf of the Company where the party
                  in question has agreed to comply fully with the letter and
                  spirit of this Policy and of the Data Protection Act 2018
                  (which may include demonstrating to the Company that all
                  suitable technical and organisational measures have been
                  taken).
                </li>
              </ol>
            </div>

            <h5 className="text-start mb-2">Data Security - Disposal</h5>
            <p className="text-start mb-2">
              When any personal data is to be erased or otherwise disposed of
              for any reason (including where copies have been made and are no
              longer needed), it should be securely deleted and disposed of.
            </p>
            <h5 className="text-start mb-2">
              Data Security - Use of Personal Data
            </h5>
            <p className="text-start mb-2">
              The Company shall ensure that the following measures are taken
              with respect to the use of personal data:
            </p>
            <div className="text-start mb-2">
              <ol>
                <li>
                  No personal data may be shared informally and if an employee,
                  agent, sub-contractor, or other party working on behalf of the
                  Company requires access to any personal data that they do not
                  already have access to, such access should be formally
                  requested from The Data Protection Officer,
                </li>
                <li>
                  No personal data may be transferred to any employees, agents,
                  contractors, or other parties, whether such parties are
                  working on behalf of the Company or not, without the
                  authorisation of The Data Protection Officer,
                </li>
                <li>
                  Personal data must be handled with care at all times and
                  should not be left unattended or on view to unauthorised
                  employees, agents, sub-contractors, or other parties at any
                  time;
                </li>
                <li>
                  If personal data is being viewed on a computer screen and the
                  computer in question is to be left unattended for any period
                  of time, the user must lock the computer and screen before
                  leaving it; and
                </li>
                <li>
                  Where personal data held by the Company is used for marketing
                  purposes, it shall be the responsibility of Wayne M Sundborg
                  to ensure that the appropriate consent is obtained and that no
                  data subjects have opted out, whether directly or via a
                  third-party service such as the TPS.
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">Data Security - IT Security</h5>
            <p className="text-start mb-2">
              The Company shall ensure that the following measures are taken
              with respect to IT and information security:
            </p>
            <div className="text-start mb-2">
              <ol>
                <li>
                  All passwords used to protect personal data should be changed
                  regularly and should not use words or phrases that can be
                  easily guessed or otherwise compromised. All passwords must
                  contain a combination of uppercase and lowercase letters,
                  numbers, and symbols;
                </li>
                <li>
                  Under no circumstances should any passwords be written down or
                  shared between any employees, agents, contractors, or other
                  parties working on behalf of the Company, irrespective of
                  seniority or department. If a password is forgotten, it must
                  be reset using the applicable method. IT staff do not have
                  access to passwords;
                </li>
                <li>
                  All software (including, but not limited to, applications and
                  operating systems) shall be kept up-to-date. The Company’s IT
                  staff shall be responsible for installing any and all
                  security-related updates after the updates are made available
                  by the publisher or manufacturer, unless there are valid
                  technical reasons not to do so; and{" "}
                </li>
                <li>
                  No software may be installed on any Company-owned computer or
                  device without the prior approval of the Company.
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">Organisational Measures</h5>
            <p className="text-start mb-2">
              The Company shall ensure that the following measures are taken
              with respect to the collection, holding, and processing of
              personal data:
            </p>
            <div className="text-start mb-2">
              <ol>
                <li>
                  All employees, agents, contractors, or other parties working
                  on behalf of the Company shall be made fully aware of both
                  their individual responsibilities and the Company’s
                  responsibilities under the Data Protection Act 2018 and under
                  this Policy, and shall be provided with a copy of this Policy;
                </li>
                <li>
                  Only employees, agents, sub-contractors, or other parties
                  working on behalf of the Company that need access to, and use
                  of, personal data in order to carry out their assigned duties
                  correctly shall have access to personal data held by the
                  Company;
                </li>
                <li>
                  All employees, agents, contractors, or other parties working
                  on behalf of the Company handling personal data will be
                  appropriately trained to do so;
                </li>
                <li>
                  All employees, agents, contractors, or other parties working
                  on behalf of the Company handling personal data will be
                  appropriately supervised;
                </li>
                <li>
                  All employees, agents, contractors, or other parties working
                  on behalf of the Company handling personal data shall be
                  required and encouraged to exercise care, caution, and
                  discretion when discussing work-related matters that relate to
                  personal data, whether in the workplace or otherwise;
                </li>
                <li>
                  Methods of collecting, holding, and processing personal data
                  shall be regularly evaluated and reviewed;
                </li>
                <li>
                  All personal data held by the Company shall be reviewed
                  periodically, as set out in the Company’s Data Retention
                  Policy;
                </li>
                <li>
                  The performance of those employees, agents, contractors, or
                  other parties working on behalf of the Company handling
                  personal data shall be regularly evaluated and reviewed;
                </li>
                <li>
                  All employees, agents, contractors, or other parties working
                  on behalf of the Company handling personal data will be bound
                  to do so in accordance with the principles of the Data
                  Protection Act 2018 and this Policy by contract;
                </li>
                <li>
                  All agents, contractors, or other parties working on behalf of
                  the Company handling personal data must ensure that any and
                  all of their employees who are involved in the processing of
                  personal data are held to the same conditions as those
                  relevant employees of the Company arising out of this Policy
                  and the Data Protection Act 2018; and
                </li>
                <li>
                  Where any agent, contractor or other party working on behalf
                  of the Company handling personal data fails in their
                  obligations under this Policy that party shall indemnify and
                  hold harmless the Company against any costs, liability,
                  damages, loss, claims or proceedings which may arise out of
                  that failure.
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">
              Transferring Personal Data to a Country Outside the EEA
            </h5>
            <p className="text-start mb-2">
              The Company may from time-to-time transfer (‘transfer’ includes
              making available remotely) personal data to countries outside of
              the EEA.
            </p>
            <p className="text-start mb-2">
              The transfer of personal data to a country outside of the EEA
              shall take place only if one or more of the following applies:
            </p>
            <div className="text-start mb-2">
              <ol>
                <li>
                  The transfer is to a country, territory, or one or more
                  specific sectors in that country (or an international
                  organisation), that the European Commission has determined
                  ensures an adequate level of protection for personal data;
                </li>
                <li>
                  The transfer is to a country (or international organisation)
                  which provides appropriate safeguards in the form of a legally
                  binding agreement between public authorities or bodies;
                  binding corporate rules; standard data protection clauses
                  adopted by the European Commission; compliance with an
                  approved code of conduct approved by a supervisory authority
                  (e.g. the Information Commissioner’s Office); certification
                  under an approved certification mechanism (as provided for in
                  the Data Protection Act 2018); contractual clauses agreed and
                  authorised by the competent supervisory authority; or
                  provisions inserted into administrative arrangements between
                  public authorities or bodies authorised by the competent
                  supervisory authority;
                </li>
                <li>
                  The transfer is made with the informed consent of the relevant
                  data subject(s);
                </li>
                <li>
                  The transfer is necessary for the performance of a contract
                  between the data subject and the Company (or for
                  pre-contractual steps taken at the request of the data
                  subject);
                </li>
                <li>
                  The transfer is necessary for important public interest
                  reasons;
                </li>
                <li>
                  The transfer is necessary for the conduct of legal claims;
                </li>
                <li>
                  The transfer is necessary to protect the vital interests of
                  the data subject or other individuals where the data subject
                  is physically or legally unable to give their consent; or
                </li>
                <li>
                  The transfer is made from a register that, under UK or EU law,
                  is intended to provide information to the public and which is
                  open for access by the public in general or otherwise to those
                  who are able to show a legitimate interest in accessing the
                  register.
                </li>
              </ol>
            </div>
            <h5 className="text-start mb-2">Data Breach Notification</h5>
            <div className="text-start mb-2">
              <ol>
                <li>
                  All personal data breaches must be reported immediately to the
                  Company’s Data Protection Officer.
                </li>
                <li>
                  If a personal data breach occurs and that breach is likely to
                  result in a risk to the rights and freedoms of data subjects
                  (e.g., financial loss, breach of confidentiality,
                  discrimination, reputational damage, or other significant
                  social or economic damage), the Data Protection Officer must
                  ensure that the Information Commissioner’s Office is informed
                  of the breach without delay, and in any event, within 72 hours
                  after having become aware of it.
                </li>
                <li>
                  In the event that a personal data breach is likely to result
                  in a high risk (that is, a higher risk than that described
                  under Part 29.2) to the rights and freedoms of data subjects,
                  the Data Protection Officer must ensure that all affected data
                  subjects are informed of the breach directly and without undue
                  delay.
                </li>
                <li>
                  Data breach notifications shall include the following
                  information:
                </li>
                <div className="text-start mb-2">
                  <ul>
                    <li>
                      •The categories and approximate number of data subjects
                      concerned;
                    </li>
                    <li>
                      •The categories and approximate number of personal data
                      records concerned;
                    </li>
                    <li>
                      • The name and contact details of the Company’s data
                      protection officer (or other contact point where more
                      information can be obtained);
                    </li>
                    <li>• The likely consequences of the breach;</li>
                    <li>
                      • Details of the measures taken, or proposed to be taken,
                      by the Company to address the breach including, where
                      appropriate, measures to mitigate its possible adverse
                      effects.
                    </li>
                  </ul>
                </div>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DataPolicy;
