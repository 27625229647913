import React from "react";
import "../Style/NewPassword.css";
import { Modal, Button, Form } from "react-bootstrap";
import { useState } from "react";
import { newPasswordApi } from "../APIS/MainApis";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";

function NewPassword({ newPasswordModal, setnewPasswordModal, token }) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [showNewPassword, setNewShowPassword] = useState(true);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);

  const resetNewPasswordForm = () => {
    setNewPassword("");
    setConfirmPassword("");
    setValidationErrors({});
  };

  const validateNewPassword = () => {
    const errors = {};
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(newPassword)) {
      errors.newpassword =
        "Password must be at least 8 characters and include at least one lowercase letter, one uppercase letter, one digit, and one special character.";
    }
    if (newPassword !== confirmPassword) {
      errors.confirmpassword = "New Password and Confirm Password not match";
    }
    if (!newPassword) {
      errors.newpassword = "NewPassword cannot be empty";
    }

    if (!confirmPassword) {
      errors.confirmpassword = "Confirm password cannot be empty";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (validationErrors.newpassword) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, newpassword: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (validationErrors.confirmpassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        confirmpassword: "",
      }));
    }
  };

  const handleSave = async () => {
    if (validateNewPassword()) {
      try {
        const response = await newPasswordApi(
          token,
          newPassword,
          confirmPassword
        );
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "Password Reset Successfully",
            text: response.data.message,
          });
          resetNewPasswordForm();
          setnewPasswordModal(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Password Reset Failed",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.log("Error changing password:", error);
      }
    } else {
      console.log("Passwords do not match");
    }
  };

  return (
    <div>
      <Modal
        show={newPasswordModal}
        onHide={() => {setnewPasswordModal(false);resetNewPasswordForm();}}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="newpassword_title">New Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>


            <Form.Group controlId="newPassword">
              <Form.Label className="newpassword-label">
                New Password
              </Form.Label>
              <div className="newpassword_input">
                <Form.Control
                  style={{
                    border: validationErrors.newpassword ? "1px solid red" : "",
                    color: validationErrors.newpassword ? "red" : "",
                  }}
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Enter new password"
                  value={newPassword}
                  className="newpassword-formcontrol"
                  onChange={handleNewPasswordChange}
                />
                <div
                  className="newpassword_toggle"
                  onClick={() => setNewShowPassword(!showNewPassword)}
                >
                  {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
              {validationErrors.newpassword && (
                <div className="newpassword_error">
                  {validationErrors.newpassword}
                </div>
              )}
            </Form.Group>


            <Form.Group controlId="confirmPassword">
              <Form.Label className="newpassword-label">
                Confirm Password
              </Form.Label>
              <div className="confirmpassword_input">
              <Form.Control
                style={{
                  border: validationErrors.confirmpassword
                    ? "1px solid red"
                    : "",
                  color: validationErrors.confirmpassword ? "red" : "",
                }}
                type={showconfirmPassword ? "text" : "password"}
                placeholder="Confirm new password"
                value={confirmPassword}
                className="newpassword-formcontrol"
                onChange={handleConfirmPasswordChange}
              />
              <div
                  className="confirmpassword_toggle"
                  onClick={() => setshowconfirmPassword(!showconfirmPassword)}
                >
                  {showconfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
              {validationErrors.confirmpassword && (
                <div className="confirmpassword_error">
                  {validationErrors.confirmpassword}
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="newpassword_button"
            onClick={handleSave}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewPassword;
