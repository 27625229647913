import React from "react";
import "../Style/CategoryLearning2Reviews.css";
import { AiFillStar } from "react-icons/ai";
import { useState } from "react";
import { SubmitReviewApi } from "../APIS/MainApis";
import { useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";

function CategoryLearning2Reviews({ about }) {
  const token = localStorage.getItem("token");
  const user = useSelector((state) => state?.user?.user);

  const params = useParams();
  const courseId = params?.courseId;

  const averageRating = !isNaN(about?.avgRating)
    ? parseFloat(about?.avgRating)
    : 0;

  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const reviewsPerPage = 5;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastReview = (currentPage + 1) * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = about?.courseReview?.slice(
    indexOfFirstReview,
    indexOfLastReview
  );

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleReviewTextChange = (event) => {
    setReviewText(event?.target?.value);
  };

  const handleSubmitReview = async () => {
    if (!user) {
      Swal.fire({
        icon: "error",
        text: "Login to continue.",
      });
      return;
    }
    if (rating === 0) {
      Swal.fire({
        icon: "error",
        text: "Please provide a rating before submitting your review.",
      });
      return;
    }
    const newUserReview = {
      course_id: courseId,
      rating: rating,
      review: reviewText,
    };

    const response = await SubmitReviewApi(newUserReview, token);
    console.log(response);
    Swal.fire({
      icon: "success",
      text: response?.data,
    });

    setRating(0);
    setReviewText("");
  };

  const resetRating = () => {
    setRating(0);
  };

  return (
    <div className="cl_reviews">
      <div className="container p-3">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="mb-4 text-start fw-bold">
              How students rated this course
            </h3>
            <div className="row align-items-center">
              <div className="cl_reviewsrating col-md-3 col-sm-12 text-center">
                <StarRatings
                  rating={averageRating}
                  starRatedColor="#f69c08"
                  starEmptyColor="#E0E0E0"
                  numberOfStars={5}
                  starDimension="13px"
                  starSpacing="2px"
                  name="rating"
                  required
                />
                <h3 className="fw-bold"> {averageRating?.toFixed(1)} </h3>
              </div>

              <div className="col-md-6 col-6 pt-3">
                <div className="progress mb-3">
                  <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: `${about?.fiveStarPercent}%` }}
                    aria-valuenow={about?.fiveStarPercent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>

                <div className="progress mb-3">
                  <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: `${about?.fourStarPercent}%` }}
                    aria-valuenow={about?.fourStarPercent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>

                <div className="progress mb-3">
                  <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: `${about?.threeStarPercent}%` }}
                    aria-valuenow={about?.threeStarPercent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>

                <div className="progress mb-3">
                  <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: `${about?.twoStarPercent}%` }}
                    aria-valuenow={about?.twoStarPercent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>

                <div className="progress mb-3">
                  <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: `${about?.oneStarPercent}%` }}
                    aria-valuenow={about?.oneStarPercent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="col-md-3 col-6">
                <div>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>{" "}
                  <span className="cl_reviews_percentage">
                    {about?.fiveStarPercent}%
                  </span>
                </div>

                <div>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>{" "}
                  <span className="cl_reviews_percentage">
                    {about?.fourStarPercent}%
                  </span>
                </div>

                <div>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>{" "}
                  <span className="cl_reviews_percentage">
                    {about?.threeStarPercent}%
                  </span>
                </div>

                <div>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>{" "}
                  <span className="cl_reviews_percentage">
                    {about?.twoStarPercent}%
                  </span>
                </div>

                <div>
                  <span className="cl_reviews_percent">
                    <AiFillStar />
                  </span>{" "}
                  <span className="cl_reviews_percentage">
                    {about?.oneStarPercent}%
                  </span>
                </div>
              </div>
            </div>
            <hr></hr>
            <h3 className="mb-4 text-start fw-bold">Write A Review</h3>
            <div className="d-flex flex-column">
              <div className="d-flex mb-3">
                {[1, 2, 3, 4, 5].map((star) => (
                  <span
                    key={star}
                    className={`cl_user_star reviews ${
                      star <= rating ? "active" : ""
                    }`}
                    onClick={() => handleRatingChange(star)}
                    onMouseEnter={() => resetRating()}
                  >
                    <AiFillStar />
                  </span>
                ))}
              </div>
              <textarea
                className="form-control submitreview_formcontrol"
                rows="4"
                placeholder="Write your review here..."
                value={reviewText}
                onChange={handleReviewTextChange}
              ></textarea>
              <div className="text-start mt-3">
                <button
                  className="submitreview_button"
                  onClick={handleSubmitReview}
                >
                  Submit Review
                </button>
              </div>
            </div>
            <hr></hr>
            <h3 className="mb-4 text-start fw-bold">Reviews</h3>

            {currentReviews?.length === 0 ? (
              <>
                <div>No Reviews to show</div>
              </>
            ) : (
              <>
                {currentReviews?.map((review, index) => (
                  <div className="users_reviews" key={index}>
                    <div className="user_info mb-3 d-flex align-items-center">
                      <div className="user_image">
                        {review?.user_name.charAt(0)}
                      </div>
                      <div className="user_details">
                        <p className="user_name text-start">
                          {review?.user_name}
                        </p>
                        <div className="d-flex align-items-center justify-content-center cl_reviewsinfo">
                          <div className="user_rating">
                            <StarRatings
                              rating={review?.rating}
                              starRatedColor="#f69c08"
                              starEmptyColor="#E0E0E0"
                              numberOfStars={5}
                              starDimension="12px"
                              starSpacing="0px"
                              name={`rating-${index}`}
                            />
                          </div>
                          <div className="review_time">
                            {review?.created_at}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="review_text text-start">{review?.review}</p>
                  </div>
                ))}
              </>
            )}

            {about?.courseReview?.length > reviewsPerPage && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={Math.ceil(
                  about?.courseReview?.length / reviewsPerPage
                )}
                pageRangeDisplayed={0}
                marginPagesDisplayed={0}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryLearning2Reviews;
