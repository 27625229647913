import React from "react";
import "../Style/BlogsDescription.css";
import Navbar from "../Components/Navbar";
import { useParams } from "react-router-dom";
import { fetchBlogPostApi } from "../APIS/MainApis";
import { useEffect, useState } from "react";
import Footer from "../Components/Footer";

function BlogsDescriptions() {
  const params = useParams();

  const [blogpostdata, setBlogPostData] = useState([]);

  useEffect(() => {
    async function fetchblogpost() { 
      const response = await fetchBlogPostApi(params.blogId);
      if(response && response.data && response.data.data && response.data.data.blog_post_data[0])
      {
        setBlogPostData(response.data.data.blog_post_data[0]);
      }
    }

    fetchblogpost();
  }, [params.blogId]);

  return (
    <>
      <Navbar />
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12">
            <img
              src={blogpostdata?.images}
              className="blogdescription_image"
              alt=""
            ></img>
            <div className="text-start mt-5">
              <h5>{blogpostdata?.title}</h5>
            </div>
            <div className="text-start">
              <p>{blogpostdata?.post_description}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BlogsDescriptions;
