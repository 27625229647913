import React from "react";
import "../Style/ContactUs.css";
import { MdLocationOn } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import { ContactUsApi } from "../APIS/MainApis";
import { fetchAdminContactDetails } from "../APIS/MainApis";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";

function ContactUs() {
  const [admindata, setAdminData] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    async function fetchadmindata() {
      const response = await fetchAdminContactDetails();

      setAdminData(response?.data?.data?.contact_data[0]);
    }
    fetchadmindata();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contactusdata = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };
    try {
      const response = await ContactUsApi(contactusdata);

      Swal.fire({
        icon: "success",
        title: "Contact Us",
        text: response.data.message,
      });
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <div className="contact-us">
      <Navbar />
      <div className="container py-5">
        <div className="row justify-content-center mt-5">
          <div className="col-12 col-lg-2"></div>
          <div className="col-12 col-lg-8">
            <div className="row g-3">
              <div className="col-12 col-lg-4">
                <div className="contact-card d-flex flex-column align-items-center">
                  <MdLocationOn className="contact-icon" />
                  <h4 className="contact-title">Our Main Office</h4>
                  <p className="contact-text">{admindata.address} </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="contact-card d-flex flex-column align-items-center">
                  <AiFillPhone className="contact-icon" />
                  <h4 className="contact-title">Phone Number</h4>
                  <p className="contact-text">+44-0800-009-6199</p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="contact-card d-flex flex-column align-items-center">
                  <MdEmail className="contact-icon" />
                  <h4 className="contact-title">Email</h4>
                  <p className="contact-text">{admindata.email}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>

        <div className="row">
          <div className="col-md-6 offset-md-3 text-center mt-5 mb-5 contact-form-background">
            <h2 className="contact-form-heading mb-3">Contact Us</h2>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control contact-input"
                  placeholder="Enter Your Name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control contact-input"
                  placeholder="Enter a valid Email Address"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <textarea
                  className="form-control contact-input"
                  rows="4"
                  placeholder="Message"
                  value={formData.message}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                />
              </div>
              <button type="submit" className="contact-submit-button">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
