import React, { useState, useEffect } from "react";
import "../Style/Cart.css";
import { add, remove } from "../store/cartSlice";
import { MdDelete } from "react-icons/md";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { fetchCartApi } from "../APIS/MainApis";
import { useDispatch } from "react-redux";
import { DeleteCartApi } from "../APIS/MainApis";
import { clearcart } from "../store/cartSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "../Auth/Login";
import SignUp from "../Auth/Signup";

function Cart() {
  const [showSignup, setShowSignup] = useState(false);
  const [showSignin, setShowSignin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const handleNavigate = (totalprice) => {
    navigate(`/payment`, { state: { totalprice } });
    window.scrollTo(0, 0);
  };

  const token = localStorage.getItem("token");

  const cartFromRedux = useSelector((state) => state?.cart?.items);
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.user);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (user) {
      async function fetchCartData() {
        try {
          fetchCartApi(token, (data) => {
            const response = data;
            if (response && response.data && response.data.cartItems) {
              setProducts(response.data.cartItems);
            }
          });
        } catch (error) {
          console.error("Error fetching cart data:", error);
        }
      }
      fetchCartData();
    }
  }, [token, user]);

  const handleDeleteProduct = async (id) => {
    const deletedata = {
      courseId: [id],
    };
    try {
      await DeleteCartApi(deletedata, token);
      setProducts((prevProducts) =>
        prevProducts?.filter((product) => product.id !== id)
      );
      dispatch(clearcart());

      fetchCartApi(token, (data) => {
        const response = data;
        if (response && response.data && response.data.cartItems) {
          for (const item of response.data.cartItems) {
            dispatch(add(item));
          }
        }
      });
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const handleDeleteProductfromredux = (id) => {
    dispatch(remove(id));
  };

  const totalprice = () => {
    if (user) {
      return products?.reduce(
        (total, product) => total + parseFloat(product.coursePrice),
        0
      );
    } else {
      return cartFromRedux?.reduce(
        (total, product) => total + parseFloat(product.courseDiscount_price),
        0
      );
    }
  };

  const openSignupModal = (e) => {
    e.preventDefault();
    setShowSignup(true);
    setShowSignin(false);
  };

  const openSigninModal = () => {
    setShowSignin(true);
    setShowSignup(false);
  };

  const closeModals = () => {
    setShowSignup(false);
    setShowSignin(false);
  };
  return (
    <>
      <Login
        show={showSignin}
        onClose={closeModals}
        onSwitchToSignup={openSignupModal}
        setIsLoggedIn={setIsLoggedIn}
      />
      <SignUp
        show={showSignup}
        onClose={closeModals}
        onSwitchToSignin={openSigninModal}
      />
      <Navbar />
      <div className="container cart_container py-5">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-12">
            {user ? (
              <table className="table cartTable">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.length === 0 ? (
                    <tr>
                      <td colSpan="4">Your cart is empty</td>
                    </tr>
                  ) : (
                    products?.map((product, i) => (
                      <tr key={i}>
                        <td>
                          <img
                            className="cart-image"
                            src={product?.courseImage}
                            alt=""
                          />
                        </td>
                        <td>{product?.courseName}</td>
                        <td>${product?.coursePrice}</td>
                        <td>
                          <MdDelete
                            className="cart_deletebutton"
                            onClick={() => handleDeleteProduct(product.id)}
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            ) : (
              <table className="table cartTable">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cartFromRedux?.length === 0 ? (
                    <tr>
                      <td colSpan="4">Your cart is empty</td>
                    </tr>
                  ) : (
                    cartFromRedux?.map((product, i) => (
                      <tr key={i}>
                        <td>
                          <img
                            className="cart-image"
                            src={product?.course_image}
                            alt=""
                          />
                        </td>
                        <td>{product?.title}</td>
                        <td>${product?.courseDiscount_price}</td>
                        <td className="cart_deletebutton">
                          <MdDelete
                            className="cart_deletebutton"
                            onClick={() =>
                              handleDeleteProductfromredux(product.id)
                            }
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}
          </div>

          {user ? (
            <div className="col-12 d-flex flex-column align-items-end justify-content-center cart_price_box">
              <div className="cart_checkout">
                <div className="totalPrice">
                  <p>Total Price: &#163; {totalprice()}</p>
                </div>
                {totalprice() !== 0 ? (
                  <button
                    className="checkout-button"
                    onClick={() => handleNavigate(totalprice())}
                  >
                    Checkout
                  </button>
                ) : (
                  <button
                    className="checkout-button"
                    onClick={() =>
                      toast.error("Please add atleast one item to cart")
                    }
                  >
                    Cart is Empty
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="col-12 d-flex flex-column align-items-end justify-content-center cart_price_box">
              <div className="cart_checkout">
                <div className="totalPrice">
                  <p>Total Price: &#163; {totalprice()}</p>
                </div>
                <button className="checkout-button" onClick={openSigninModal}>
                  Login To Continue
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Cart;
