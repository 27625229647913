import React from "react";
import Banner from "../Components/Banner"; 
import Category from "../Components/Category";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import StudentMessage from "../Components/StudentMessage";
import Classes from "../Components/Classes";
import Premium from "../Components/Premium";
import DemoVideo from "../Components/DemoVideo";
import Reason from "../Components/Reason";
import OnlineClasses from "../Components/OnlineClasses";
import "../Style/Home.css"


function Home() {
  return ( 
    <>
      <div className="back_banner_image">
        <Navbar />
        <Banner />
      </div>
      <Category />
      <Reason />
      <OnlineClasses />
      <Premium />
      <DemoVideo />
      <Classes />
      <StudentMessage />
      <Footer />
    </>
  );
}

export default Home;
