import React from "react";
import { useLocation } from "react-router-dom";
import "../Style/SearchCategory.css";
import Navbar from "../Components/Navbar";
import StarRatings from "react-star-ratings";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

 
function SearchCategory() {


  const location = useLocation();
  const searchResults = location.state ? location.state.searchResults : [];

  const navigate = useNavigate();
  const handleNavigate = (courseId) => {
    navigate(`/singlecategory/${courseId}`);
    window.scrollTo(0, 0);
  };

  return (
    <div> 
      <Navbar />
      <div className="container py-5 searchcategory_container">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
          {searchResults && searchResults.length>0 ? (
            searchResults.map((course, index) => (
              <div className="col gy-3" key={index}>
                <div className="card" onClick={() => handleNavigate(course.id)}>
                  <img src={course.image} className="searchcategory_image" alt="" /> 
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <span className="searchcategory_level">{course.level}</span>
                      <h5 className="searchcategory_price">
                        ${course.discount_price}
                      </h5>
                    </div> 
                    <p className="truncated-title text-start py-2">{course.name}</p>
                    <div className="d-flex mb-2 searchcategory_whom">
                      By: {course.author}
                    </div>
                    <div className="text-start category_span">
                      <div className="d-flex align-items-center">
                        <span className="searchcategory_avgrating"> {parseFloat(course.avgRating.toFixed(1))}</span> 
                        <span className="searchcategory_comments">({course.totalUsers})</span>
                      </div>
                        <StarRatings
                          rating={course.avgRating}
                          starRatedColor="#f69c08"
                          starEmptyColor="#E0E0E0"
                          numberOfStars={5} 
                          starDimension="13px"
                          starSpacing="2px"
                          name="rating"
                        />
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between align-items-center">
                    <div>
                      <button className="searchcategory_enroll">
                        View Details
                      </button>
                    </div>
                    {/* <div>
                      <button className="searchcategory_enroll">
                        Add to Wishlist
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            ))
          ):(
            <p>No results Found</p>
          )}
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default SearchCategory;
