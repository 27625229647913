import React from 'react'

function DemoVideo() {
  return (
    <>
      <div className="demovideo-container py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 mb-2">
              <h3 className="fw-bold">
                Demo <span className="special">Video</span>
              </h3>
            </div>
              <video className="demo-video" width="1000" controls> 
                <source 
                  src="Risk Assessment  Unraveling Hazards and Risks.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
          </div>
        </div>
      </div>
    </>
  );
}

export default DemoVideo